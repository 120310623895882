import React, { useState } from "react";
import { Form } from "react-bootstrap";

const SmallCustomCheckbox = ({ handleChange, name, label, val }) => {
  const [checked, setChecked] = useState(false);

  return (
    <div className="boxsm">
      <input
        type="checkbox"
        onChange={handleChange}
        value={val}
        name={name}
        id=""
      />
      <div className="boxContent">
        <p>{label}</p>
      </div>
    </div>
  );
};
export default SmallCustomCheckbox;
