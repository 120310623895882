import React from "react";
import { Card, Row, Container, Col, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";
import {
  faSort,
  faSortUp,
  faSquarePollVertical,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const School = () => {
  return (
    <React.Fragment>
      <Helmet title="My School" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3 mt-2">Loyola High School</h1>
        <Card>
          <Card.Body>Infomration about the school goes here</Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default School;
