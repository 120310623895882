import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import FullTable from "../../../../ui/tables/FullTable";
import QuickActions from "../quick_actions/QuickActions";

const OverviewTable = () => {
  const value = [
    {
      academic_year: "2023",
      semester: "First semester",
      subjects: "12",
      resources_link: "https://12cdk.vinly",
    },
    {
      academic_year: "2022",
      semester: "Second semester",
      subjects: "12",
      resources_link: "https://12cdk.vinly",
    },
    {
      academic_year: "2021",
      semester: "Second semester",
      subjects: "12",
      resources_link: "https://12cdk.vinly",
    },
    {
      academic_year: "2021",
      semester: "First semester",
      subjects: "14",
      resources_link: "https://12cdk.vinly",
    },
  ];
  const columns = [
    {
      Header: "Academic year",
      accessor: "academic_year",
    },
    {
      Header: "Semester",
      accessor: "semester",
    },
    {
      Header: "Subjects/Courses",
      accessor: "subjects",
    },
    {
      Header: "Resources",
      accessor: "resources_link",
    },
  ];
  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <FullTable values={value} cols={columns} />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default OverviewTable;
