import React, { useState } from "react";
import { Row, Col, Container, ButtonGroup, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import FullTable from "../../../../ui/tables/FullTable";
import QuickActions from "../quick_actions/QuickActions";
import OverviewTable from "./OverviewTable";
import OverviewTree from "./OverviewTree";
import { Table, List } from "react-feather";

const CurriculumOverview = () => {
  const [currentOverview, setCurrentOverview] = useState("table");
  return (
    <React.Fragment>
      <h1 className="h3 mb-3">Overview</h1>

      <div className="d-flex flex-row justify-content-end">
        <ButtonGroup>
          <Button
            onClick={(e) => setCurrentOverview("table")}
            variant={currentOverview === "table" ? "primary" : "secondary"}
          >
            <Table size={18} />
          </Button>
          <Button
            onClick={(e) => setCurrentOverview("tree")}
            variant={currentOverview === "tree" ? "primary" : "secondary"}
          >
            <List size={18} />
          </Button>
        </ButtonGroup>
      </div>

      {currentOverview === "table" ? <OverviewTable /> : <OverviewTree />}
    </React.Fragment>
  );
};

export default CurriculumOverview;
