import React from "react";
import { Card, Row, Container, Col, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";
import {
  faSort,
  faSortUp,
  faSquarePollVertical,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullTable from "../../../ui/tables/FullTable";

const Reward = () => {
  const cols = [
    {
      Header: "Academic year",
      accessor: "academic_year",
    },
    {
      Header: "Semester",
      accessor: "semester",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Description",
      accessor: "description",
    },
  ];
  const rows = [
    {
      id: 1,
      academic_year: "2022",
      semester: "2nd Semester",
      type: "Reward",
      description: "Best in Mathematics",
    },
    {
      id: 2,
      academic_year: "2022",
      semester: "2nd Semester",
      type: "Reward",
      description: "Best in English",
    },
  ];
  return (
    <React.Fragment>
      <Helmet title="Rewards" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3 mt-2">My Rewards & Punishments</h1>
        <Card>
          <Card.Body>
            <FullTable values={rows} cols={cols} />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Reward;
