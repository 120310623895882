import React from "react";
import { Button } from "react-bootstrap";
import { User } from "react-feather";
import { Link } from "react-router-dom";
import { quick_actions_list } from "./quick_actions_list";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const QuickActions = () => {
  return (
    <React.Fragment>
      <h1 className="h3 mb-3">Quick Actions</h1>
      <div className="d-flex flex-row justify-content-around flex-wrap p-3">
        {quick_actions_list.map((action) => (
          <Link
            key={action.title}
            className="quick-action-link m-2"
            to={action.link}
          >
            <div className="d-flex flex-column align-items-center justify-content-between">
              <FontAwesomeIcon
                className="align-middle"
                icon={action.icon}
                size="3x"
              />
              <p className="pt-4">{action.title}</p>
            </div>
          </Link>
        ))}
      </div>
    </React.Fragment>
  );
};

export default QuickActions;
