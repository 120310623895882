import React from "react";
import { Card, Button } from "react-bootstrap";
import News from "./News";
const NewsCard = () => {
  const news = [
    {
      id: 23,
      school: "Feza boys",
      message:
        "Dear parents, our school appreciate the development in your child which has occurred over the years...",
      link: "https://www.google.com",
      school_icon: "",
      date: "01/12/2022",
    },
    {
      id: 24,
      school: "BYU High School",
      message:
        "Dear parents, our school appreciate the development in your child which has occurred over the years...",
      link: "https://www.google.com",
      school_icon: "",
      date: "01/12/2022",
    },
    {
      id: 25,
      school: "Feza boys",
      message:
        "Dear parents, our school appreciate the development in your child which has occurred over the years...",
      link: "https://www.google.com",
      school_icon: "",
      date: "01/12/2022",
    },
  ];
  return (
    <Card className="mb-3 bg-light cursor-grab border">
      <Card.Header>
        <h3>News</h3>
      </Card.Header>
      <Card.Body className="p-3">
        {news.map((n) => (
          <News
            key={n.id}
            school={n.school}
            message={n.message}
            link
            date={n.date}
            school_icon={n.school_icon}
          />
        ))}
      </Card.Body>
    </Card>
  );
};
export default NewsCard;
