import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Row,
  Container,
  Col,
  Badge,
  Button,
  Modal,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users, Mail, MessageSquare } from "react-feather";
import TeachersTable from "./TeachersTable";
import PopulationCard from "./PopulationCard";

const TeacherManagementPage = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Helmet title="Teacher Management" />
      <Container fluid className="p-0">
        <div className="d-flex mb-3 flex-row justify-content-between">
          <h1 className="h3 ">Teacher Management</h1>
          <Button
            onClick={() => navigate("add-teacher")}
            variant="outline-primary"
          >
            New Teacher
          </Button>
        </div>
        <Row className="equal-height-col">
          <Col md={4}>
            <PopulationCard />
          </Col>
          <Col md={4}>{/* <EnrollmentStatusCard /> */}</Col>
          <Col md={4}>{/* <SearchStudentCard /> */}</Col>
        </Row>
        <Row>
          <TeachersTable />
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default TeacherManagementPage;
