import React, { useState } from "react";
import {
  Card,
  Row,
  Container,
  Col,
  Alert,
  Form,
  Badge,
  Button,
  Modal,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users, Mail, MessageSquare } from "react-feather";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";

const EnrollStudentPage = () => {
  return (
    <React.Fragment>
      <Helmet title="Enroll Student" />
      <Container fluid className="p-0">
        <div className="d-flex mb-3 flex-row justify-content-between">
          <h1 className="h3 ">Enroll New Student</h1>
        </div>
        <Card>
          <Card.Header>
            <h2>Enroll A New Student</h2>
          </Card.Header>
          <Card.Body>
            <Formik
              initialValues={{
                personal_information: {
                  first_name: "",
                  middle_name: "",
                  last_name: "",
                  gender: "",
                  nationality: "",
                  date_of_birth: "",
                },
                contact_information: {
                  email: "",
                  phone_number: "",
                  emergency_phone_number: "",
                  address: "",
                },
                parent_guardian_information: {
                  first_name: "",
                  last_name: "",
                  relationship: "",
                  contact_information: {
                    email: "",
                    phone_number: "",
                    secondary_phone_number: "",
                    address: "",
                  },
                },
                academic_information: {
                  previous_school: "",
                  grade_level: "",
                },
                health_information: {
                  medical_conditions: [],
                  medications: [],
                },
                additional_details: {
                  interests: [],
                  special_needs: [],
                },
              }}
              validationSchema={Yup.object().shape({
                personal_information: Yup.object().shape({
                  first_name: Yup.string().required("First name is required!"),
                  middle_name: Yup.string(),
                  last_name: Yup.string().required("Last name is required!"),
                  gender: Yup.string().required("Gender is required!"),
                  nationality: Yup.string().required(
                    "Nationality is required!"
                  ),
                  date_of_birth: Yup.date().required(
                    "Date of birth is required!"
                  ),
                }),
                contact_information: Yup.object().shape({
                  email: Yup.string().email("Must be a valid email!"),
                  phone_number: Yup.string(),
                  emergency_phone_number: Yup.string().required(
                    "Emergency phone number is required!"
                  ),
                  address: Yup.string().required("Address is required!"),
                }),
                parent_guardian_information: Yup.object().shape({
                  first_name: Yup.string().required("First name is required!"),
                  last_name: Yup.string().required("Last name is required!"),
                  contact_information: Yup.object().shape({
                    email: Yup.string().email("Must be a valid email"),
                    phone_number: Yup.string().required(
                      "Phone number is required"
                    ),
                    secondary_phone_number: Yup.string(),
                    address: Yup.string().required("Address is required"),
                  }),
                }),
                academic_information: Yup.object().shape({
                  previous_school: Yup.string(),
                  grade_level: Yup.string().required("Grade level is required"),
                }),
                health_information: Yup.object().shape({
                  medical_conditions: Yup.array(),
                  medications: Yup.array(),
                }),
                additional_details: Yup.object().shape({
                  interests: Yup.array(),
                  special_needs: Yup.array(),
                }),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  console.log("ENROLL STUDENT FORM", values);
                } catch (error) {
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}
                  <h3 className="mb-4">Student's Personal Information</h3>
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.personal_information.first_name}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.first_name
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.first_name"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.first_name}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Middle name</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.personal_information.middle_name}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.middle_name
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.middle_name"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.middle_name}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.personal_information.last_name}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.last_name
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.last_name"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.last_name}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-6">
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Nationality</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.personal_information.nationality}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.nationality
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.nationality"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.nationality}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Gender</Form.Label>
                        <Form.Select
                          size="lg"
                          value={values.personal_information.middle_name}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.gender
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.gender"
                        >
                          <option></option>
                          <option value="male">Male</option>
                          <option value="femaile">Female</option>
                        </Form.Select>
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.gender}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Date of birth</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.personal_information.date_of_birth}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.date_of_birth
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="date"
                          name="personal_information.date_of_birth"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.date_of_birth}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <h3 className="mb-4">Student's Contact Information</h3>
                  <Row className="mb-6">
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.personal_information.phone_number}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.phone_number
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.phone_number"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.phone_number}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Emergency Phone number</Form.Label>
                        <Form.Control
                          size="lg"
                          value={
                            values.personal_information.emergency_phone_number
                          }
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.emergency_phone_number
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.emergency_phone_number"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.emergency_phone_number}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.personal_information.address}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.address
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.address"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.address}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <h3 className="mb-4">Parent/Guardian Information</h3>
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.parent_guardian_information.first_name}
                          isInvalid={Boolean(
                            touched.parent_guardian_information &&
                              errors.parent_guardian_information["first_name"]
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.first_name"
                        />
                        {!!touched.parent_guardian_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.parent_guardian_information.first_name}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.parent_guardian_information.last_name}
                          isInvalid={Boolean(
                            touched.parent_guardian_information &&
                              errors.parent_guardian_information.last_name
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.last_name"
                        />
                        {!!touched.parent_guardian_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.parent_guardian_information.last_name}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Relationship</Form.Label>
                        <Form.Control
                          size="lg"
                          value={
                            values.parent_guardian_information.relationship
                          }
                          isInvalid={Boolean(
                            touched.parent_guardian_information &&
                              errors.parent_guardian_information.relationship
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="parent_guardian_information.relationship"
                        />
                        {!!touched.parent_guardian_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.parent_guardian_information.relationship}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <h5 className="mb-3">Contact Information</h5>
                  <Row className="mb-6">
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control
                          size="lg"
                          value={
                            values.parent_guardian_information.phone_number
                          }
                          isInvalid={Boolean(
                            touched.parent_guardian_information &&
                              errors.parent_guardian_information.phone_number
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="parent_guardian_information.phone_number"
                        />
                        {!!touched.parent_guardian_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.parent_guardian_information.phone_number}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Emal</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.parent_guardian_information.email}
                          isInvalid={Boolean(
                            touched.parent_guardian_information &&
                              errors.parent_guardian_information.email
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="parent_guardian_information.email"
                        />
                        {!!touched.parent_guardian_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.parent_guardian_information.email}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Secondary Phone number</Form.Label>
                        <Form.Control
                          size="lg"
                          value={
                            values.parent_guardian_information
                              .secondary_phone_number
                          }
                          isInvalid={Boolean(
                            touched.parent_guardian_information &&
                              errors.parent_guardian_information
                                .secondary_phone_number
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="parent_guardian_information.secondary_phone_number"
                        />
                        {!!touched.parent_guardian_information && (
                          <Form.Control.Feedback type="invalid">
                            {
                              errors.parent_guardian_information
                                .secondary_phone_number
                            }
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.parent_guardian_information.address}
                          isInvalid={Boolean(
                            touched.parent_guardian_information &&
                              errors.parent_guardian_information.address
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="parent_guardian_information.address"
                        />
                        {!!touched.parent_guardian_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.parent_guardian_information.address}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <h3 className="mb-4">Parent/Guardian Information</h3>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Previous School</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.academic_information.previous_school}
                          isInvalid={Boolean(
                            touched.academic_information &&
                              errors.academic_information.previous_school
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="academic_information.previous_school"
                        />
                        {!!touched.academic_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.academic_information.previous_school}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Grade Level</Form.Label>
                        <Form.Select
                          size="lg"
                          value={values.academic_information.grade_level}
                          isInvalid={Boolean(
                            touched.academic_information &&
                              errors.academic_information.grade_level
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="academic_information.grade_level"
                        >
                          <option></option>
                          <option>Grade 1</option>
                          <option>Grade 2</option>
                          <option>Grade 3</option>
                        </Form.Select>
                        {!!touched.academic_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.academic_information.grade_level}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <h3 className="mb-4">Health Information</h3>
                  <Row className="mb-6">
                    <h5 className="mb-3">Medical conditions</h5>
                    <FieldArray
                      name="health_information.medical_conditions"
                      render={(arrayHelpers) => (
                        <Row>
                          {values.health_information.medical_conditions &&
                          values.health_information.medical_conditions.length >
                            0 ? (
                            <div>
                              {values.health_information.medical_conditions.map(
                                (condition, index) => (
                                  <Col key={index} md={2}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Medical Condition</Form.Label>
                                      <Form.Control
                                        name={`health_information.medical_conditions[${index}]`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        size="lg"
                                        value={condition}
                                        isInvalid={Boolean(
                                          touched.health_information &&
                                            errors.health_information
                                              .medical_conditions[index]
                                        )}
                                      />
                                      {!!touched.health_information && (
                                        <Form.Control.Feedback type="invalid">
                                          {
                                            errors.health_information
                                              .medical_conditions[index]
                                          }
                                        </Form.Control.Feedback>
                                      )}
                                    </Form.Group>
                                    <Button
                                      variant="outline-warning"
                                      onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                    >
                                      -
                                    </Button>

                                    <Button
                                      variant="outline-primary"
                                      onClick={() =>
                                        arrayHelpers.insert(index, "")
                                      } // insert an empty string at a position
                                    >
                                      +
                                    </Button>
                                  </Col>
                                )
                              )}
                            </div>
                          ) : (
                            <Button
                              variant="outline-primary"
                              onClick={() => arrayHelpers.push("")}
                            >
                              Add Medical Condition
                            </Button>
                          )}
                        </Row>
                      )}
                    />
                  </Row>
                  <Row className="mb-3">
                    <h5 className="mb-3">Medications</h5>
                    <FieldArray
                      name="health_information.medications"
                      render={(arrayHelpers) => (
                        <Row>
                          {values.health_information.medications &&
                          values.health_information.medications.length > 0 ? (
                            <div>
                              {values.health_information.medications.map(
                                (condition, index) => (
                                  <Col key={index} md={2}>
                                    <Form.Group className="mb-3">
                                      <Form.Label>Medication</Form.Label>
                                      <Form.Control
                                        name={`health_information.medications[${index}]`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        size="lg"
                                        value={condition}
                                        isInvalid={Boolean(
                                          touched.health_information &&
                                            errors.health_information
                                              .medications[index]
                                        )}
                                      />
                                      {!!touched.health_information && (
                                        <Form.Control.Feedback type="invalid">
                                          {
                                            errors.health_information
                                              .medications[index]
                                          }
                                        </Form.Control.Feedback>
                                      )}
                                    </Form.Group>
                                    <Button
                                      variant="outline-warning"
                                      onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                                    >
                                      -
                                    </Button>

                                    <Button
                                      variant="outline-primary"
                                      onClick={() =>
                                        arrayHelpers.insert(index, "")
                                      } // insert an empty string at a position
                                    >
                                      +
                                    </Button>
                                  </Col>
                                )
                              )}
                            </div>
                          ) : (
                            <Button
                              variant="outline-primary"
                              onClick={() => arrayHelpers.push("")}
                            >
                              Add Medication
                            </Button>
                          )}
                        </Row>
                      )}
                    />
                  </Row>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default EnrollStudentPage;
