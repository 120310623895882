import React from "react";
import { Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

const GradeLevelCard = () => {
  return (
    <Card className="m-2 grade-level-card">
      <Card.Header>
        <h3 className="h3">Grade 1</h3>
      </Card.Header>
      <Card.Body>
        <h4>
          Total Students: <span className="display-6"> 35</span>
        </h4>
        <h5>Sections: 3</h5>
        <h5>Gender Distribution: 25/10</h5>
      </Card.Body>
      <Card.Footer>
        <div className="d-flex flex-row justify-content-end">
          <Link to="12321">View Details</Link>
        </div>
      </Card.Footer>
    </Card>
  );
};
const ClassDistribution = () => {
  return (
    <React.Fragment>
      <Card.Title tag="h3">Class Distribution</Card.Title>
      <div className="d-flex flex-row flex-wrap">
        <GradeLevelCard />
        <GradeLevelCard />
        <GradeLevelCard />
        <GradeLevelCard />
        <GradeLevelCard />
        <GradeLevelCard />
        <GradeLevelCard />
        <GradeLevelCard />
        <GradeLevelCard />
        <GradeLevelCard />
        <GradeLevelCard />
        <GradeLevelCard />
        <GradeLevelCard />
      </div>
    </React.Fragment>
  );
};
export default ClassDistribution;
