import React, { useState } from "react";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import FullTable from "../../../../ui/tables/FullTable";
import CreateAnnouncementForm from "./CreateAnnouncementForm";

const AnnouncementPage = () => {
  const cols = [
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Message",
      accessor: "message",
    },
    {
      Header: "Author",
      accessor: "author",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Actions",
      accessor: "id",
    },
  ];

  const vals = [
    {
      title: "School Closure",
      date: "May 10, 2023",
      author: "Principal",
      message:
        "Due to inclement weeather conditions, the school will be closed omorrow. Please stay safe and take necessary precautions",
    },
    {
      title: "Parent-Teacher Meeting",
      date: "May 15, 2023",
      author: "Admin",
      message:
        "We are organizing a parent-teacher meeting next week on Thursday. Please make sure to attend and discuss your child's progress with their respective teachers.",
    },
    {
      title: "Exam Schedule",
      date: "May 20, 2023",
      author: "Principal",
      message:
        "The final exam schedule for this semester is now available. Please check the school website or ontact the administration office for further details",
    },
  ];
  const [announcementForm, setAnnouncementForm] = useState(false);
  const handleClose = () => setAnnouncementForm(false);
  return (
    <React.Fragment>
      {announcementForm ? (
        <CreateAnnouncementForm handleClose={handleClose} />
      ) : null}
      <Helmet title="Announcements" />
      <Container fluid className="p-0">
        <div className="d-flex mb-3 flex-row justify-content-between">
          <h1 className="h3 mb-3">Announcements</h1>
          <Button
            onClick={(e) => setAnnouncementForm(true)}
            variant="outline-primary"
          >
            Create Announcement
          </Button>
        </div>
        <Row>
          <Card>
            <Card.Header></Card.Header>
            <Card.Body>
              <FullTable cols={cols} values={vals} />
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AnnouncementPage;
