import { User } from "react-feather";
import {
  faUser,
  faChalkboardTeacher,
  faPerson,
  faCalendar,
  faMoneyBill,
  faBook,
  faMailBulk,
  faScroll,
} from "@fortawesome/free-solid-svg-icons";
export const quick_actions_list = [
  {
    title: "Manage Subjects",
    icon: faUser,
    link: "manage-subjects",
  },
  {
    title: "Resources",
    icon: faChalkboardTeacher,
    link: "manage-resources",
  },
  {
    title: "Mapping",
    icon: faPerson,
    link: "mapping",
  },
  {
    title: "Manage Programs",
    icon: faCalendar,
    link: "manage-programs",
  },
  {
    title: "Announcements",
    icon: faMoneyBill,
    link: "announcements",
  },
];
