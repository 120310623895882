import React from "react";
import { Search } from "react-feather";
import { Card, Row, Col, Form, InputGroup, Button } from "react-bootstrap";

const SearchStudentCard = () => {
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h3">Quick Search</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form inline="true" className="d-none d-sm-inline-block">
          <InputGroup size="lg" className="input-group-navbar">
            <Form.Control size="lg" aria-label="Search" />
            <Button variant="">
              <Search className="feather" />
            </Button>
          </InputGroup>
        </Form>
      </Card.Body>
    </Card>
  );
};
export default SearchStudentCard;
