import React from "react";
import { Card, Row, Container, Col, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";
import {
  faSort,
  faSortUp,
  faSquarePollVertical,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullTable from "../../../ui/tables/FullTable";
import { useParams } from "react-router-dom";

const SemesterResults = () => {
  const { id } = useParams();

  const cols = [
    {
      Header: "Subject",
      accessor: "subject",
    },
    {
      Header: "Pass/Fail",
      accessor: "pass_or_fail",
    },
    {
      Header: "Mid-term Test",
      accessor: "mid_term",
    },
    {
      Header: "Final Examinations",
      accessor: "final_exam",
    },
    {
      Header: "Remark",
      accessor: "remark",
    },
  ];
  const rows = [
    {
      id: 1,
      subject: "Mathematics",
      pass_or_fail: "Pass",
      mid_term: "89",
      final_exam: "98",
      remark: "He performs excellently. Such a good student",
    },
    {
      id: 2,
      subject: "English",
      pass_or_fail: "Pass",
      mid_term: "89",
      final_exam: "98",
      remark: "He performs excellently. Such a good student",
    },
  ];
  return (
    <React.Fragment>
      <Helmet title="Parent Dashboard" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3 mt-2">2022 January - First Semester</h1>
        <Card>
          <Card.Body>
            <FullTable values={rows} cols={cols} />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default SemesterResults;
