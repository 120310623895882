import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Alert } from "react-bootstrap";
import TinyCustomCheckbox from "../../../../ui/custom_checkbox/TinyCustomCheckbox";
import ActionableOffcanvas from "../../../../ui/actionables/offcanvas/ActionableOffcanvas";

const SendReminderForm = ({ handleClose, recipients, fee_id }) => {
  return (
    <Formik
      initialValues={{
        message:
          "Please ensure timely payment to support quality education. Contact us for assistance if needed. [School Name]",
        delivery_method: "",
        comm_type: "",
        title: "School Fee Payment Reminder [Due Date: 03/07/2023",
      }}
      validationSchema={Yup.object().shape({
        delivery_method: Yup.string().required("Select a channel"),
        message: Yup.string().required("A message is required"),
        comm_type: Yup.string().required("Message type is required!"),
        title: Yup.string(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          console.log("FEE SETUP FORM: ", values);
        } catch (e) {}
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ActionableOffcanvas
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            action="Fee Payment"
            title={
              recipients === "all"
                ? `[SENDING TO ALL] 2023-2024 ACADEMIC YEAR TUITION FEE`
                : "2023-2024 ACADEMIC YEAR TUITION FEE"
            }
          >
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Delivery Method</Form.Label>
              <Form.Select
                size="lg"
                type=""
                name="delivery_method"
                placeholder=""
                value={values.delivery_method}
                isInvalid={Boolean(
                  touched.delivery_method && errors.delivery_method
                )}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option></option>
                <option>All</option>
                <option>SMS</option>
                <option>Email</option>
              </Form.Select>
              {!!touched.delivery_method && (
                <Form.Control.Feedback type="invalid">
                  {errors.delivery_method}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Communication Type</Form.Label>
              <Form.Select
                size="lg"
                type=""
                name="comm_type"
                placeholder=""
                value={values.comm_type}
                isInvalid={Boolean(touched.comm_type && errors.comm_type)}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option></option>
                <option>General</option>
                <option selected>Reminder</option>
                <option>Event Invintation</option>
              </Form.Select>
              {!!touched.comm_type && (
                <Form.Control.Feedback type="invalid">
                  {errors.comm_type}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                size="lg"
                name="title"
                placeholder=""
                value={values.title}
                isInvalid={Boolean(touched.title && errors.title)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.title && (
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                size="lg"
                as="textarea"
                name="message"
                rows={5}
                placeholder=""
                value={values.message}
                isInvalid={Boolean(touched.message && errors.message)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.message && (
                <Form.Control.Feedback type="invalid">
                  {errors.message}
                </Form.Control.Feedback>
              )}
              <ul>
                <li>Characters: {values.message.length}</li>
                <li>Per Message: 160</li>
                <li>Remainig: {160 - values.message.length}</li>
              </ul>
            </Form.Group>
          </ActionableOffcanvas>
        </Form>
      )}
    </Formik>
  );
};

export default SendReminderForm;
