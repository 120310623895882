import React from "react";
import { Card, Row, Container, Col, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";
import {
  faSort,
  faSortUp,
  faSquarePollVertical,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullTable from "../../../ui/tables/FullTable"; //the table componenet

const Attendance = () => {
  //defines the columns in the table
  const cols = [
    {
      Header: "Academic year",
      accessor: "academic_year",
    },
    {
      Header: "Semester",
      accessor: "semester",
    },
    {
      Header: "Be late",
      accessor: "late",
    },
    {
      Header: "Leave early",
      accessor: "early_leave",
    },
    {
      Header: "Sick days",
      accessor: "sick_days",
    },
    {
      Header: "Cut class",
      accessor: "cut_class",
    },
    {
      Header: "Total Absent Days",
      accessor: "absent_days_total",
    },
  ];
  //defines the rows in the table
  const rows = [
    {
      id: 1,
      academic_year: "2021",
      semester: "Second Semester",
      late: "0",
      early_leave: "1",
      sick_days: "0",
      cut_class: "0",
      absent_days_total: "0",
    },
    {
      id: 2,
      academic_year: "2022",
      semester: "First Semester",
      late: "2",
      early_leave: "1",
      sick_days: "7",
      cut_class: "0",
      absent_days_total: "7",
    },
    {
      id: 3,
      academic_year: "2022",
      semester: "Second Semester",
      late: "2",
      early_leave: "1",
      sick_days: "0",
      cut_class: "0",
      absent_days_total: "0",
    },
  ];
  return (
    <React.Fragment>
      <Helmet title="Attendance Dashboard" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3 mt-2">My Attendance</h1>
        <Card>
          <Card.Body>
            <FullTable values={rows} cols={cols} />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Attendance;
