import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { RefreshCw } from "react-feather";
import NoticeBox from "./NoticeBox";

const NoticeBoardCard = () => {
  return (
    <Card>
      <Card.Header>
        <Row>
          <Col>
            <h3>Notice Board</h3>
          </Col>
          <Col md={1}>
            <div className="d-flex flex-row justify-content-end">
              <Button variant="link">
                <RefreshCw size={16} />
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <div className="d-flex flex-column">
          <NoticeBox />
          <NoticeBox />
          <NoticeBox />
        </div>
      </Card.Body>
    </Card>
  );
};

export default NoticeBoardCard;
