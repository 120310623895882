import React from "react";
import { Card, Row, Container, Col, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";
import {
  faSort,
  faSortUp,
  faSquarePollVertical,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpcomingEventsCard from "./UpcomingEventsCard";
import DailyScheduleCard from "./DailyScheduleCard";
import LiveBroadCastCard from "./LiveBroadCastCard";

const StudentDash = () => {
  return (
    <React.Fragment>
      <Helmet title="Student Dashboard" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3 mt-2">Welcome</h1>
        <Row>
          <Col md={8}>
            <h1 className="display-4">Baraka Urio</h1>
            <h3>Class: Form 1 Green</h3>
          </Col>
          <Col>
            <LiveBroadCastCard />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <UpcomingEventsCard />
          </Col>
          <Col md={6}>
            <DailyScheduleCard />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default StudentDash;
