import React, { useState } from "react";
import ActionableOffcanvas from "../../../../ui/actionables/offcanvas/ActionableOffcanvas";
import { Formik } from "formik";
import { Form, Alert } from "react-bootstrap";
import * as Yup from "yup";
const CreateProgramForm = ({ handleClose }) => {
  return (
    <Formik
      initialValues={{
        name: "",
        description: "",
        type: "",
        coordinator: "",
        start_date: "",
        end_date: "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Please provide the name of the program"),
        type: Yup.string().required("Please provide the type of the subject"),
        description: Yup.string()
          .max(30)
          .required("Please provide a brief description of the program"),
        coordinator: Yup.string().required(
          "Please specify who is the coordinator"
        ),
        start_date: Yup.string().required("Specify the start date"),
        end_date: Yup.string().required("Specify the end date"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          console.log("EDIT SUBJECT FORM VALUES: ", values);
        } catch (e) {}
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ActionableOffcanvas
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            action="Create a New Program"
            title=""
          >
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Program name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="name"
                placeholder=""
                value={values.name}
                isInvalid={Boolean(touched.name && errors.name)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.name && (
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Program description</Form.Label>
              <Form.Control
                size="lg"
                as="textarea"
                name="description"
                placeholder=""
                value={values.description}
                isInvalid={Boolean(touched.description && errors.description)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.description && (
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Subject code</Form.Label>
              <Form.Control
                size="lg"
                type="code"
                name="code"
                placeholder=""
                value={values.code}
                isInvalid={Boolean(touched.code && errors.code)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.code && (
                <Form.Control.Feedback type="invalid">
                  {errors.code}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Program Coordinator</Form.Label>
              <Form.Select
                size="lg"
                name="coordinator"
                placeholder=""
                value={values.coordinator}
                isInvalid={Boolean(touched.coordinator && errors.coordinator)}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option></option>
                <option>Department of Mathematics</option>
                <option>Department of Language</option>
              </Form.Select>
              {!!touched.coordinator && (
                <Form.Control.Feedback type="invalid">
                  {errors.coordinator}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Date of start</Form.Label>
              <Form.Control
                size="lg"
                type="date"
                name="start_date"
                placeholder=""
                value={values.start_date}
                isInvalid={Boolean(touched.start_date && errors.start_date)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.start_date && (
                <Form.Control.Feedback type="invalid">
                  {errors.start_date}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Date of end</Form.Label>
              <Form.Control
                size="lg"
                type="date"
                name="end_date"
                placeholder=""
                value={values.end_date}
                isInvalid={Boolean(touched.end_date && errors.end_date)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.end_date && (
                <Form.Control.Feedback type="invalid">
                  {errors.end_date}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </ActionableOffcanvas>
        </Form>
      )}
    </Formik>
  );
};
export default CreateProgramForm;
