import React from "react";
import { Card, Row, Container, Col, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";
import {
  faSort,
  faSortUp,
  faSquarePollVertical,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullTable from "../../../ui/tables/FullTable";
import { Eye } from "react-feather";
import { useNavigate } from "react-router-dom";
import useAppSelector from "../../../hooks/useAppSelector";

const Results = () => {
  const student_id = useAppSelector((state) => state.parentStudent.student_id);
  console.log("Academic results for student with id: ", student_id);
  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate(`/student/results/${id}`);
  };
  const cols = [
    {
      Header: "Application ID",
      accessor: "application_id",
    },
    {
      Header: "Entry year",
      accessor: "entry_year",
    },
    {
      Header: "Semester",
      accessor: "semester",
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ value }) => {
        return (
          <Eye
            style={{ cursor: "pointer" }}
            onClick={(event) => handleClick(value)}
            className="m-1"
            size="24"
            color="#293042"
          />
        );
      },
    },
  ];
  const rows = [
    {
      id: 1,
      application_id: "1996568",
      entry_year: "2022 January",
      semester: "First Semester",
      action: "",
    },
    {
      id: 2,
      application_id: "1996568",
      entry_year: "2022 September",
      semester: "Second Semester",
      action: "",
    },
  ];
  return (
    <React.Fragment>
      <Helmet title="Results" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3 mt-2">My Results</h1>
        <Card>
          <Card.Body>
            <FullTable values={rows} cols={cols} />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Results;
