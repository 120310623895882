import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import FlatSimpleTable from "../../../ui/tables/FlatSimpleTable";

const RecentAnnouncements = () => {
  const data = [
    {
      id: "1",
      title: "School closure",
      message:
        "The school is scheduled to close on 1st June for the Mid-year holiday",
    },
    {
      id: "2",
      title: "School closure",
      message:
        "The school is scheduled to close on 1st June for the Mid-year holiday",
    },
    {
      id: "3",
      title: "School closure",
      message:
        "The school is scheduled to close on 1st June for the Mid-year holiday",
    },
  ];
  return (
    <Card>
      <Card.Header>
        <h1 className="h2 mb-3">Recent Announcements</h1>
      </Card.Header>
      <Card.Body>
        <ListGroup flush>
          {data.map((announcment) => (
            <ListGroup.Item key={announcment.id}>
              <h3 className="h3 mb-1">{announcment.title}</h3>
              <p>{announcment.message}</p>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default RecentAnnouncements;
