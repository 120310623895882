import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import AverageScoresBarChart from "./AverageScoresBarChart";

const Analytics = () => {
  return (
    <React.Fragment>
      <AverageScoresBarChart />
    </React.Fragment>
  );
};
export default Analytics;
