import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Row,
  Container,
  Col,
  Badge,
  Form,
  InputGroup,
  Button,
  Modal,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Search } from "react-feather";

const ResultCard = ({
  title,
  excerpt,
  id,
  tags,
  author,
  fileType,
  dateUploaded,
}) => {
  return (
    <Card className="w-50">
      <Card.Header>
        <h2 className="h3">{title} </h2>
      </Card.Header>
      <Card.Body>
        <p>{excerpt}</p>
      </Card.Body>
      <Card.Footer>
        <div className="d-flex flex-row justify-content-between">
          <div className="w-50 d-flex flex-row">
            {tags.map((tag) => (
              <Badge className="m-2" text="dark" bg="light">
                {tag}
              </Badge>
            ))}
          </div>
          <div className="d-flex flex-row justify-content-end">
            <Badge
              className="m-2"
              text="dark"
              bg="light"
            >{`File type: ${fileType}`}</Badge>
            <Badge
              className="m-2"
              text="dark"
              bg="light"
            >{`Upload: ${dateUploaded}`}</Badge>
            <Badge
              className="m-2"
              text="dark"
              bg="light"
            >{`Author: ${author}`}</Badge>
          </div>
        </div>
      </Card.Footer>
    </Card>
  );
};

const GlobalResourcesPage = () => {
  const navigate = useNavigate();
  const academicResources = [
    {
      id: 1,
      title: "Introduction to Algebra",
      excerpt:
        "Learn the basic concepts of algebra including variables, equations, and functions.",
      tags: ["Mathematics", "Algebra"],
      author: "John Smith",
      fileType: "PDF",
      dateUploaded: "2023-05-10",
    },
    {
      id: 2,
      title: "The History of World War II",
      excerpt:
        "Explore the major events and impact of World War II in this comprehensive history resource.",
      tags: ["History", "World War II"],
      author: "Jane Johnson",
      fileType: "PDF",
      dateUploaded: "2023-04-25",
    },
    // Add more resources here...
    {
      id: 10,
      title: "Introduction to Python Programming",
      excerpt:
        "Get started with Python programming language and learn the basics of coding.",
      tags: ["Computer Science", "Programming"],
      author: "Michael Brown",
      fileType: "Video",
      dateUploaded: "2023-03-15",
    },
    {
      id: 11,
      title: "Chemical Reactions Lab Manual",
      excerpt:
        "A comprehensive lab manual for conducting various chemical reactions and experiments.",
      tags: ["Chemistry", "Laboratory", "Experiments"],
      author: "Sarah Anderson",
      fileType: "PDF",
      dateUploaded: "2023-06-02",
    },
    {
      id: 12,
      title: "Literary Analysis Guide",
      excerpt:
        "An in-depth guide to analyzing and interpreting works of literature for students and educators.",
      tags: ["English", "Literature", "Analysis"],
      author: "David Thompson",
      fileType: "PDF",
      dateUploaded: "2023-05-28",
    },
    {
      id: 13,
      title: "Geography Quiz App",
      excerpt:
        "A fun and interactive quiz application to test your knowledge of world geography.",
      tags: ["Geography", "Quiz", "Education"],
      author: "Emily Roberts",
      fileType: "Mobile App",
      dateUploaded: "2023-05-20",
    },
    {
      id: 14,
      title: "Mathematics Problem Solving Techniques",
      excerpt:
        "A guidebook showcasing various problem-solving strategies and techniques in mathematics.",
      tags: ["Mathematics", "Problem Solving", "Techniques"],
      author: "John Roberts",
      fileType: "eBook",
      dateUploaded: "2023-06-10",
    },
    {
      id: 15,
      title: "Art History Timeline",
      excerpt:
        "A visual timeline depicting the major art movements and styles throughout history.",
      tags: ["Art", "History", "Timeline"],
      author: "Sophia Davis",
      fileType: "Image",
      dateUploaded: "2023-06-05",
    },
    {
      id: 16,
      title: "Computer Programming Basics",
      excerpt:
        "A beginner-friendly introduction to computer programming concepts and coding fundamentals.",
      tags: ["Computer Science", "Programming", "Basics"],
      author: "Michael Johnson",
      fileType: "Video",
      dateUploaded: "2023-06-01",
    },
    {
      id: 17,
      title: "Environmental Science Research Paper",
      excerpt:
        "A research paper discussing the impact of pollution on aquatic ecosystems and proposed solutions.",
      tags: ["Environmental Science", "Research", "Pollution"],
      author: "Jessica Brown",
      fileType: "PDF",
      dateUploaded: "2023-05-25",
    },
  ];
  return (
    <React.Fragment>
      <Helmet title="Resources" />
      <Container fluid className="p-0">
        <div className="d-flex mb-3 flex-row justify-content-between">
          <h1 className="h3 ">Global Resources</h1>
          <Button
            onClick={() => navigate("add-teacher")}
            variant="outline-primary"
          >
            New Resource
          </Button>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <Card className="w-75">
            <Card.Body>
              <Form inline="true" className="w-100 d-none d-sm-inline-block">
                <InputGroup size="lg" className="input-group-navbar">
                  <Form.Control size="lg" aria-label="Search" />
                  <Button variant="">
                    <Search className="feather" />
                  </Button>
                </InputGroup>
              </Form>
            </Card.Body>
          </Card>
        </div>
        <h3 className="h3 text-center">Results:</h3>
        <div className="d-flex flex-column align-items-center">
          {academicResources.map((res) => (
            <ResultCard
              key={res.id}
              id={res.id}
              title={res.title}
              excerpt={res.excerpt}
              dateUploaded={res.dateUploaded}
              tags={res.tags}
              fileType={res.fileType}
              author={res.author}
            />
          ))}
        </div>
      </Container>
    </React.Fragment>
  );
};

export default GlobalResourcesPage;
