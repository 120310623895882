import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { RefreshCw } from "react-feather";
import SystemMessages from "./SystemMessages";

const SystemEventsCard = () => {
  return (
    <Card>
      <Card.Header>
        <Row>
          <Col>
            <h3>System Events</h3>
          </Col>
          <Col md={1}>
            <div className="d-flex flex-row justify-content-end">
              <Button variant="link">
                <RefreshCw size={16} />
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between online-users">
            <div>
              <h1>Online Users</h1>
              <p>Last 10 mins</p>
            </div>
            <div>
              <p className="display-6">25</p>
            </div>
          </div>
          <hr></hr>
          <SystemMessages />
        </div>
      </Card.Body>
    </Card>
  );
};

export default SystemEventsCard;
