import React from "react";
import ActionableOffcanvas from "../../../ui/actionables/offcanvas/ActionableOffcanvas";
import { Formik } from "formik";
import { Form, Alert } from "react-bootstrap";
import * as Yup from "yup";
import SmallCustomCheckbox from "../../../ui/custom_checkbox/SmallCustomCheckbox";
const CreateExamAssessmentForm = ({ handleClose }) => {
  const tags = [
    {
      id: "1",
      name: "Grade 1",
    },
    {
      id: "19",
      name: "Elementary",
    },
    {
      id: "2",
      name: "Grade 2",
    },
    {
      id: "3",
      name: "Grade 3",
    },
    {
      id: "4",
      name: "Grade 4",
    },
    {
      id: "5",
      name: "Grade 5",
    },
    {
      id: "6",
      name: "Grade 6",
    },
    {
      id: "7",
      name: "Grade 7",
    },
    {
      id: "8",
      name: "Form 1",
    },
    {
      id: "9",
      name: "Form 2",
    },
    {
      id: "10",
      name: "Form 3",
    },
    {
      id: "11",
      name: "Form 4",
    },
  ];
  return (
    <ActionableOffcanvas
      handleClose={handleClose}
      action="Create Exam or Assessment"
      title=""
    >
      <Formik
        initialValues={{
          title: "",
          grade_levels: [],
          duration: "",
          start_date: "",
          end_date: "",
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().required(
            "Please provide the title of the Exam or Assessment"
          ),
          grade_levels: Yup.array()
            .min(1)
            .required("Please provide the grade levels"),
          duration: Yup.string(),
          start_date: Yup.string().required("Specify the start date"),
          end_date: Yup.string().required("Specify the end date"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            console.log("EDIT SUBJECT FORM VALUES: ", values);
          } catch (e) {}
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Exam/Assessment Title</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="title"
                placeholder=""
                value={values.title}
                isInvalid={Boolean(touched.title && errors.title)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.title && (
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Grade levels</Form.Label>
              <div className="d-flex flex-row flex-wrap">
                {tags.map((grade) => (
                  <SmallCustomCheckbox
                    key={grade.id}
                    val={grade.name}
                    label={grade.name}
                    handleChange={handleChange}
                    name="grade_levels"
                  />
                ))}
              </div>
              {!!touched.grade_levels && (
                <Form.Control.Feedback type="invalid">
                  {errors.grade_levels}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Date of start</Form.Label>
              <Form.Control
                size="lg"
                type="date"
                name="start_date"
                placeholder=""
                value={values.start_date}
                isInvalid={Boolean(touched.start_date && errors.start_date)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.start_date && (
                <Form.Control.Feedback type="invalid">
                  {errors.start_date}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Date of end</Form.Label>
              <Form.Control
                size="lg"
                type="date"
                name="end_date"
                placeholder=""
                value={values.end_date}
                isInvalid={Boolean(touched.end_date && errors.end_date)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.end_date && (
                <Form.Control.Feedback type="invalid">
                  {errors.end_date}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form>
        )}
      </Formik>
    </ActionableOffcanvas>
  );
};
export default CreateExamAssessmentForm;
