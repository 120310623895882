import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Alert, Button } from "react-bootstrap";
import CustomCheckbox from "../../../../../../ui/custom_checkbox/CustomCheckbox";
import ActionableOffcanvas from "../../../../../../ui/actionables/offcanvas/ActionableOffcanvas";

const AddRemoveGradesForm = ({ handleClose }) => {
  const school_grades = [
    {
      id: "1",
      name: "Grade 1",
    },
    {
      id: "2",
      name: "Grade 2",
    },
    {
      id: "3",
      name: "Grade 3",
    },
    {
      id: "4",
      name: "Grade 4",
    },
    {
      id: "5",
      name: "Grade 5",
    },
    {
      id: "6",
      name: "Grade 6",
    },
    {
      id: "7",
      name: "Grade 7",
    },
    {
      id: "8",
      name: "Form 1",
    },
    {
      id: "9",
      name: "Form 2",
    },
    {
      id: "10",
      name: "Form 3",
    },
    {
      id: "11",
      name: "Form 4",
    },
  ];
  return (
    <Formik
      initialValues={{
        grades_taught: [],
      }}
      validationSchema={Yup.object().shape({
        grades_taught: Yup.array(),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          console.log("ADD REMOVE GRADES FORM VALUES: ", values);
        } catch (e) {}
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ActionableOffcanvas
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            action="Add/Remove Grade"
            title="Basic Mathematics"
          >
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Form.Group>
              <Form.Label>Select the grades for this Subject</Form.Label>
              <div className="d-flex flex-row flex-wrap">
                {school_grades.map((grade) => (
                  <CustomCheckbox
                    key={grade.id}
                    handleChange={handleChange}
                    val={grade.name}
                    name="grades_taught"
                    label={grade.name}
                  />
                ))}
              </div>
            </Form.Group>
          </ActionableOffcanvas>
        </Form>
      )}
    </Formik>
  );
};

export default AddRemoveGradesForm;
