import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form, Row, Col } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import useAuth from "../../hooks/useAuth";
// import country_dial_codes from "../../utils/country_dial_codes";

function StudentRegister() {
  const navigate = useNavigate();
  const { signUp } = useAuth();

  return (
    <Formik
      initialValues={{
        parent_name: "",
        gender: "",
        date_of_birth: "",
        full_name: "",
        email: "",
        parent_email: "",
        school_name: "",
        class_id: "",
        password: "",
        confirm_password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        full_name: Yup.string().max(255).required("First name is required"),
        parent_name: Yup.string().max(25).required("Parent name is required"),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        parent_email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        gender: Yup.string().required("Gender is required"),
        school_name: Yup.string().required("School name is required"),
        date_of_birth: Yup.string().required("Date of birth is required"),
        class_id: Yup.string().required("Class ID is required"),
        phone_number: Yup.string().required("Phone number is required").max(13),
        country_code: Yup.string().required("Country code required").max(5),
        password: Yup.string()
          .min(12, "Must be at least 12 characters")
          .max(255)
          .required("Required"),
        confirm_password: Yup.string()
          .min(12, "Must be at least 12 characters")
          .max(255)
          .required("Required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        //slicing the phone nnumber
        if (values.phone_number.length >= 10) {
          values.phone_number = values.phone_number.slice(1);
        }
        values.phone_number = values.country_code + values.phone_number;
        values.phone_number.slice(1);
        try {
          signUp(
            values.email,
            values.password,
            values.first_name,
            values.last_name,
            values.phone_number
          );
          navigate("/auth/sign-in");
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              {errors.submit}
            </Alert>
          )}
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Student's full name</Form.Label>
                <Form.Control
                  type="text"
                  className="no-outline-textfield form-control-lg"
                  name="full_name"
                  placeholder="Full name"
                  value={values.full_name}
                  isInvalid={Boolean(touched.full_name && errors.full_name)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.full_name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.full_name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Gender</Form.Label>
                <Form.Select
                  className="no-outline-textfield form-control-lg"
                  name="gender"
                  value={values.gender}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Date of Birth</Form.Label>
                <br></br>
                <Form.Control
                  type="date"
                  className="no-outline-textfield form-control-lg"
                  name="date_of_birth"
                  value={values.date_of_birth}
                  isInvalid={Boolean(
                    touched.date_of_birth && errors.date_of_birth
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.date_of_birth && (
                  <Form.Control.Feedback type="invalid">
                    {errors.date_of_birth}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Student's email</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  className="no-outline-textfield form-control-lg"
                  placeholder="Last name"
                  value={values.email}
                  isInvalid={Boolean(touched.email && errors.email)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.last_name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Parent's/Guardian's email</Form.Label>
                <Form.Control
                  type="email"
                  name="parent_email"
                  className="no-outline-textfield form-control-lg"
                  placeholder="Email address"
                  value={values.parent_email}
                  isInvalid={Boolean(
                    touched.parent_email && errors.parent_email
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.parent_email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.parent_email}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Parent's or Guardian's name</Form.Label>
                <Form.Control
                  type="text"
                  name="parent_name"
                  className="no-outline-textfield form-control-lg"
                  placeholder=""
                  value={values.parent_name}
                  isInvalid={Boolean(touched.parent_name && errors.parent_name)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.parent_name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.parent_name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>School's name</Form.Label>
                <Form.Control
                  type="text"
                  name="school_name"
                  placeholder=""
                  className="no-outline-textfield form-control-lg"
                  value={values.school_name}
                  isInvalid={Boolean(touched.school_name && errors.school_name)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.school_name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.school_name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Class ID</Form.Label>
                <Form.Control
                  type="text"
                  name="class_id"
                  className="no-outline-textfield form-control-lg"
                  placeholder=""
                  value={values.class_id}
                  isInvalid={Boolean(touched.class_id && errors.class_id)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.class_id && (
                  <Form.Control.Feedback type="invalid">
                    {errors.class_id}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  className="no-outline-textfield form-control-lg"
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  isInvalid={Boolean(touched.password && errors.password)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirm_password"
                  placeholder="Password"
                  className="no-outline-textfield form-control-lg"
                  value={values.confirm_password}
                  isInvalid={Boolean(
                    touched.confirm_password && errors.confirm_password
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.confirm_password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.confirm_password}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>

          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              UPDATE
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default StudentRegister;
