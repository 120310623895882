import React from "react";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import FullTable from "../../../../ui/tables/FullTable";
import { Link } from "react-router-dom";

const ManageSubjects = () => {
  const columns = [
    {
      Header: "Code",
      accessor: "code",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Department",
      accessor: "department",
    },
    {
      Header: "Success rate",
      accessor: "success_rate",
      Cell: ({ value }) => {
        if (value >= 80) {
          return <p className="text-primary">{`${value}%`}</p>;
        } else if (value >= 65) {
          return <p className="text-success">{`${value}%`}</p>;
        } else if (value >= 50) {
          return <p className="text-warning">{`${value}%`}</p>;
        } else if (value < 50) {
          return <p className="text-danger">{`${value}%`}</p>;
        } else {
          return <p>not available</p>;
        }
      },
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value }) => (
        <div className="d-flex flex-row">
          <Link
            className="pe-3"
            to="/admin/academic-curriculum/manage-subjects/subject_id"
          >
            View
          </Link>
          <Link to="/admin">Delete</Link>
        </div>
      ),
    },
  ];

  const value = [
    {
      id: "1",
      name: "Basic Mathematics",
      code: "BAM233",
      department: "Mathematics",
      success_rate: 80,
    },
    {
      id: "2",
      name: "English",
      code: "ENG233",
      department: "Language",
      success_rate: 90,
    },
    {
      id: "3",
      name: "Kiswahili",
      code: "KWH233",
      department: "Language",
      success_rate: 78,
    },
    {
      id: "4",
      name: "Physics",
      code: "PHY233",
      department: "Science",
      success_rate: 89,
    },
    {
      id: "5",
      name: "Chemistry",
      code: "CHM233",
      department: "Science",
      success_rate: 29,
    },
    {
      id: "6",
      name: "Biology",
      code: "BIO233",
      department: "Science",
      success_rate: 52,
    },
  ];
  return (
    <React.Fragment>
      <Helmet title="Manage subjects" />
      <Container fluid className="p-0">
        <div className="d-flex flex-row justify-content-between">
          <h1 className="h3 mb-3">Manage Subjects</h1>
          <Button className="mb-3" variant="outline-primary">
            New Subject
          </Button>
        </div>
        <Card>
          <Card.Body>
            <FullTable cols={columns} values={value} />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default ManageSubjects;
