import React from "react";
import { Card, Row, Container, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";
import { faUser, faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StudentRegister from "../../components/auth/StudentRegister";

const ParentLogin = () => {
  return (
    <React.Fragment>
      <Helmet title="Student Dashboard" />
      <Card>
        <Card.Body>
          <div className="text-center pb-5 pt-5">
            <FontAwesomeIcon icon={faUserCircle} className="display-1" />
          </div>
          <StudentRegister />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default ParentLogin;
