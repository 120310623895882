import React from "react";
import { Card, Button } from "react-bootstrap";
import FlatSimpleTable from "../../../ui/tables/FlatSimpleTable";
import { Link } from "react-router-dom";
import TimeTable from "react-timetable-events";

const DailyScheduleCard = () => {
  return (
    <Card>
      <Card.Header>
        <div className="d-flex flex-row justify-content-between">
          <div>
            <h1>Today's Schedule</h1>
          </div>
          <div>
            <Link to="/student/timetable">View Timetable</Link>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <TimeTable
          className="daily-schedule-card-timetable"
          events={{
            monday: [
              {
                id: 1,
                name: "Mathematics",
                type: "custom",
                startTime: new Date("2022-12-01T11:30:00"),
                endTime: new Date("2022-12-01T13:30:00"),
              },
              {
                id: 2,
                name: "English",
                type: "custom",
                startTime: new Date("2022-12-01T13:40:00"),
                endTime: new Date("2022-12-01T15:30:00"),
              },
              {
                id: 3,
                name: "Free period",
                type: "custom",
                startTime: new Date("2022-12-01T15:35:00"),
                endTime: new Date("2022-12-01T16:30:00"),
              },
            ],
          }}
          hoursInterval={{
            from: 8,
            to: 17,
          }}
          style={{ height: "400px" }}
        />
      </Card.Body>
    </Card>
  );
};
export default DailyScheduleCard;
