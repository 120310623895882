import React, { useState } from "react";
import { Offcanvas, Button } from "react-bootstrap";

const ActionableOffcanvas = ({
  action,
  title,
  children,
  handleClose,
  handleSubmit,
}) => {
  return (
    <Offcanvas
      className="vw-50 rounded-end"
      backdrop
      show={true}
      onHide={handleClose}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <h1 className="h1 mb-3">{action}</h1>
          <h3 className="h3 mb-3">{title}</h3>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <hr></hr>
      <Offcanvas.Body>{children}</Offcanvas.Body>
      <div className="d-flex flex-row justify-content-end bg-light p-3">
        <Button
          onClick={handleSubmit}
          className="m-1"
          size="lg"
          variant="primary"
        >
          Submit
        </Button>
        <Button
          onClick={handleClose}
          className="m-1"
          size="lg"
          variant="outline-danger"
        >
          Cancel
        </Button>
      </div>
    </Offcanvas>
  );
};

export default ActionableOffcanvas;
