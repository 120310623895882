import React, { useState } from "react";
import { Row, Col, Container, Card, Button, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

const ProgramPage = () => {
  const grades_intended = ["Grade 3", "Grade 4", "Grade 5"];
  return (
    <React.Fragment>
      <Helmet title="Swimming Lessons" />

      <Container fluid className="p-0">
        <div className="mb-3 d-flex flex-row justify-content-between">
          <h1 className="h3 mb-3">Swimming Lessons</h1>
          <Button variant="outline-primary">Edit program</Button>
        </div>
        <Row className="mb-3">
          <Col>
            <Card>
              <Card.Header>
                <Card.Title>
                  <h1>
                    <strong>Program name: </strong>Art Lessons
                  </h1>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <h2 className="h2">Description</h2>
                    <p>
                      This program offers creative at lessons to students in
                      grades 5 and 6. Students will explore various art
                      techniiques and mediums, including painting, drawing and
                      sculpture.
                    </p>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex flex-row justify-content-around">
                      <div>
                        <h3>Start date</h3>
                        <p>1st September 2022</p>
                      </div>
                      <div>
                        <h3>End date</h3>
                        <p>4th December 2022</p>
                      </div>
                      <div>
                        <h3>Duration</h3>
                        <p>4 months</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2 className="h2 mt-5">
                      Instructor:{" "}
                      <Badge bg="light" text="dark">
                        Mr. Michael Meliki
                      </Badge>
                    </h2>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col>
                    <h2 className="h2">Intended for</h2>
                    <div className="d-flex flex-row justify-content-around">
                      {grades_intended.map((grade) => (
                        <Badge pill bg="primary">
                          {grade}
                        </Badge>
                      ))}
                    </div>
                  </Col>
                  <Col>
                    <h2 className="h2">Location</h2>
                    <p>Classroom A301</p>
                  </Col>
                  <Col>
                    <h2 className="h2">Fees</h2>
                    <p>TZS 54,000 /-</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={2}>
            <Card>
              <Card.Header>
                <Card.Title>Total enrollment</Card.Title>
              </Card.Header>
              <Card.Body>
                <p className="text-center display-2">35</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ProgramPage;
