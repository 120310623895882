import React from "react";
import { Card } from "react-bootstrap";
import unsplash1 from "../../assets/img/photos/unsplash-1.jpg";

const ResourceCard = ({ title, desc, link }) => {
  return (
    <Card className="w-100">
      <Card.Header>
        <Card.Title tag="h2" className="mb-0">
          {title}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Card.Text>{desc}</Card.Text>
        <Card.Link href={link}>Card link</Card.Link>
        <Card.Link href="#">Another link</Card.Link>
      </Card.Body>
    </Card>
  );
};

export default ResourceCard;
