import React from "react";
import { Badge, Card } from "react-bootstrap";
import FullTable from "../../../ui/tables/FullTable";
import { Link } from "react-router-dom";

const TeachersTable = () => {
  const cols = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Subjects",
      accessor: "subjects",
      Cell: ({ value }) => (
        <div className="d-flex flex-row">
          {value.map((subject) => (
            <p className="m-1">{subject},</p>
          ))}
        </div>
      ),
    },
    {
      Header: "Grade Level",
      accessor: "grade_level",
      Cell: ({ value }) => (
        <div className="d-flex flex-row">
          {value.map((grade) => (
            <p className="m-1">{grade},</p>
          ))}
        </div>
      ),
    },
    {
      Header: "Specializations",
      accessor: "specializations",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => {
        switch (value) {
          case "Active":
            return (
              <Badge className="p-2" bg="success">
                {value}
              </Badge>
            );
          case "Inactive":
            return <Badge bg="warning">{value}</Badge>;
          default:
            return <Badge bg="light">{value}</Badge>;
        }
      },
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value }) => (
        <div className="d-flex flex-row justify-content-between">
          <Link to="323424">View</Link>
          <Link to="">Edit</Link>
        </div>
      ),
    },
  ];
  const vals = [
    {
      id: "1",
      name: "Baraka Elias Urio",
      subjects: ["Physics", "Chemistry"],
      grade_level: ["Form 2", "Form 4"],
      specializations: ["Science"],
      status: "Active",
    },
    {
      id: "1",
      name: "Baraka Elias Urio",
      subjects: ["Physics", "Chemistry"],
      grade_level: ["Form 2", "Form 4"],
      specializations: ["Science"],
      status: "Active",
    },
    {
      id: "1",
      name: "Baraka Elias Urio",
      subjects: ["Physics", "Chemistry"],
      grade_level: ["Form 2", "Form 4"],
      specializations: ["Science"],
      status: "Active",
    },
    {
      id: "1",
      name: "Baraka Elias Urio",
      subjects: ["Physics", "Chemistry"],
      grade_level: ["Form 2", "Form 4"],
      specializations: ["Science"],
      status: "Active",
    },
    {
      id: "1",
      name: "Baraka Elias Urio",
      subjects: ["Physics", "Chemistry"],
      grade_level: ["Form 2", "Form 4"],
      specializations: ["Science"],
      status: "Active",
    },
    {
      id: "1",
      name: "Baraka Elias Urio",
      subjects: ["Physics", "Chemistry"],
      grade_level: ["Form 2", "Form 4"],
      specializations: ["Science"],
      status: "Active",
    },
    {
      id: "1",
      name: "Baraka Elias Urio",
      subjects: ["Physics", "Chemistry"],
      grade_level: ["Form 2", "Form 4"],
      specializations: ["Science"],
      status: "Active",
    },
    {
      id: "1",
      name: "Baraka Elias Urio",
      subjects: ["Physics", "Chemistry"],
      grade_level: ["Form 2", "Form 4"],
      specializations: ["Science"],
      status: "Active",
    },
    {
      id: "1",
      name: "Baraka Elias Urio",
      subjects: ["Physics", "Chemistry"],
      grade_level: ["Form 2", "Form 4"],
      specializations: ["Science"],
      status: "Active",
    },
    {
      id: "1",
      name: "Baraka Elias Urio",
      subjects: ["Physics", "Chemistry"],
      grade_level: ["Form 2", "Form 4"],
      specializations: ["Science"],
      status: "Active",
    },
    {
      id: "1",
      name: "Baraka Elias Urio",
      subjects: ["Physics", "Chemistry"],
      grade_level: ["Form 2", "Form 4"],
      specializations: ["Science"],
      status: "Active",
    },
    {
      id: "1",
      name: "Baraka Elias Urio",
      subjects: ["Physics", "Chemistry"],
      grade_level: ["Form 2", "Form 4"],
      specializations: ["Science"],
      status: "Active",
    },
    {
      id: "1",
      name: "Baraka Elias Urio",
      subjects: ["Physics", "Chemistry"],
      grade_level: ["Form 2", "Form 4"],
      specializations: ["Science"],
      status: "Active",
    },
    {
      id: "1",
      name: "Baraka Elias Urio",
      subjects: ["Physics", "Chemistry"],
      grade_level: ["Form 2", "Form 4"],
      specializations: ["Science"],
      status: "Active",
    },
  ];
  return (
    <Card>
      <Card.Header>
        <h2 className="h2">All Teachers</h2>
      </Card.Header>
      <Card.Body>
        <FullTable values={vals} cols={cols} />
      </Card.Body>
    </Card>
  );
};
export default TeachersTable;
