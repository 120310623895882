import React from "react";
import { Card, Row, Container, Col, Badge, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";
import QuickActions from "./quick_actions/QuickActions";
import { useParams } from "react-router-dom";
import CommunicationForm from "./CommunicationForm";

const CommunicatePage = () => {
  const { audience } = useParams();
  console.log(audience);
  return (
    <React.Fragment>
      <Helmet title="Parent Communication" />
      <Container fluid className="p-0">
        <div className="d-flex mb-3 flex-row justify-content-between">
          <h1 className="h3 ">
            {audience.charAt(0).toUpperCase() + audience.slice(1)} Communication
          </h1>
        </div>
        <Row>
          <CommunicationForm />
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default CommunicatePage;
