import { createSlice } from "@reduxjs/toolkit";
import { userSlice } from "./user";
export const parentStudentSlice = createSlice({
  name: "parentStudent",
  initialState: {
    student_id: null,
  },
  reducers: {
    setActiveStudentId: (state, action) => {
      console.log("Setting active student redux", action.payload);
      state.student_id = action.payload;
    },
  },
});

export function setActiveStudent(id) {
  return async (dispatch) => {
    dispatch(parentStudentSlice.actions.setActiveStudentId(id));
  };
}
export const { setActiveStudentId } = parentStudentSlice.actions;
export default parentStudentSlice.reducer;
