import React from "react";
import { Card, ListGroup, Badge, Button } from "react-bootstrap";

const MappingResults = () => {
  const data = [
    {
      id: "1",
      name: "Basic Mathematics",
      grades: [
        {
          id: "3",
          name: "Grade 3",
        },
        {
          id: "4",
          name: "Grade 4",
        },
        {
          id: "5",
          name: "Grade 5",
        },
        {
          id: "6",
          name: "Grade 6",
        },
        {
          id: "7",
          name: "Grade 7",
        },
        {
          id: "8",
          name: "Form 1",
        },
        {
          id: "9",
          name: "Form 2",
        },
        {
          id: "10",
          name: "Form 3",
        },
        {
          id: "11",
          name: "Form 4",
        },
      ],
    },
    {
      id: "2",
      name: "English",
      grades: [
        {
          id: "3",
          name: "Grade 3",
        },
        {
          id: "4",
          name: "Grade 4",
        },
        {
          id: "5",
          name: "Grade 5",
        },
        {
          id: "6",
          name: "Grade 6",
        },
        {
          id: "7",
          name: "Grade 7",
        },
        {
          id: "8",
          name: "Form 1",
        },
        {
          id: "9",
          name: "Form 2",
        },
        {
          id: "10",
          name: "Form 3",
        },
        {
          id: "11",
          name: "Form 4",
        },
      ],
    },
  ];
  return (
    <Card className="h-100">
      <Card.Header>
        <Card.Title>
          <h1 className="h3">Mapping results</h1>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <div className="d-flex h-100 flex-column justify-content-between">
          <ListGroup className="h-75" style={{ overflowY: "scroll" }}>
            {data.map((subject) => (
              <ListGroup.Item key={subject.id}>
                <h3>{subject.name}</h3>
                {subject.grades.map((grade) => (
                  <Badge className="p-2 m-3" bg="info">
                    {grade.name}
                  </Badge>
                ))}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <div className="d-flex flex-row justify-content-end">
            <Button size="lg" variant="primary">
              Submit New Mapping
            </Button>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default MappingResults;
