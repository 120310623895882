import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Alert } from "react-bootstrap";
import ActionableOffcanvas from "../../../../../../ui/actionables/offcanvas/ActionableOffcanvas";

const EditSubjectForm = ({ handleClose }) => {
  return (
    <Formik
      initialValues={{
        name: "",
        code: "",
        description: "",
        department: "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Please provide the name of the subject"),
        code: Yup.string().required("Please provide the code of the subject"),
        description: Yup.string(),
        department: Yup.string().required(
          "Please provide the department this subject belongs to"
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          console.log("EDIT SUBJECT FORM VALUES: ", values);
        } catch (e) {}
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ActionableOffcanvas
            handleClose={handleClose}
            action="Edit Subject"
            handleSubmit={handleSubmit}
            title="Basic Mathematics"
          >
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Subject name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="name"
                placeholder=""
                value={values.name}
                isInvalid={Boolean(touched.name && errors.name)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.name && (
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Subject code</Form.Label>
              <Form.Control
                size="lg"
                type="code"
                name="code"
                placeholder=""
                value={values.code}
                isInvalid={Boolean(touched.code && errors.code)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.code && (
                <Form.Control.Feedback type="invalid">
                  {errors.code}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Subject Department</Form.Label>
              <Form.Select
                size="lg"
                type="department"
                name="department"
                placeholder=""
                value={values.department}
                isInvalid={Boolean(touched.department && errors.department)}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option></option>
                <option>Department of Mathematics</option>
                <option>Department of Language</option>
              </Form.Select>
              {!!touched.department && (
                <Form.Control.Feedback type="invalid">
                  {errors.department}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </ActionableOffcanvas>
        </Form>
      )}
    </Formik>
  );
};

export default EditSubjectForm;
