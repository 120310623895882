import React from "react";
import { Card, Row, Container, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";
import { faUser, faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SignIn from "../../../components/auth/SignIn";

const ParentLogin = () => {
  return (
    <React.Fragment>
      <Helmet title="Parent Dashboard" />
      <Container className="p-0" style={{ margin: "auto" }}>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row className="pb-5">
                  <FontAwesomeIcon icon={faUserCircle} className="display-1" />
                </Row>
                <Row>
                  <Col>
                    <SignIn />
                  </Col>
                  <Col>
                    <h3 className="text-center">Openknowledge</h3>
                    <Button size="lg">Request a Parent Account</Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ParentLogin;
