import React from "react";
import { Form, Alert, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import ActionableOffcanvas from "../../../ui/actionables/offcanvas/ActionableOffcanvas";

const BroadcastForm = ({ handleClose }) => {
  return (
    <Formik
      initialValues={{
        channel: "",
        author: "",
        recipients: "",
        sender_id: "",
        message: "",
      }}
      validationSchema={Yup.object().shape({
        channel: Yup.string().required("Please select a Broadcast Channel"),
        author: Yup.string().required("Specify the author"),
        recipients: Yup.string()
          .max(30)
          .required("Please provide a brief description of the program"),
        sender_id: Yup.string().required("Please select a sender ID"),
        message: Yup.string().required("A message is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          console.log("EDIT SUBJECT FORM VALUES: ", values);
        } catch (e) {}
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ActionableOffcanvas
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            action="Create a New Program"
            title=""
          >
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Channel</Form.Label>
              <Form.Select
                size="lg"
                name="channel"
                placeholder=""
                value={values.channel}
                isInvalid={Boolean(touched.channel && errors.channel)}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option value="all">All</option>
                <option value="sms">SMS</option>
                <option value="email">Email</option>
              </Form.Select>
              {!!touched.channel && (
                <Form.Control.Feedback type="invalid">
                  {errors.channel}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Author</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="author"
                placeholder=""
                value={values.author}
                isInvalid={Boolean(touched.author && errors.author)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.author && (
                <Form.Control.Feedback type="invalid">
                  {errors.author}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Recipients</Form.Label>
              <Form.Select
                size="lg"
                name="recipients"
                placeholder=""
                value={values.recipients}
                isInvalid={Boolean(touched.recipients && errors.recipients)}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option value="all">All</option>
                <option value="parents">All Parents</option>
                <option value="teachers">All Teachers</option>
                <option value="students">All Students</option>
                <option value="staff">All Staff</option>
              </Form.Select>
              {!!touched.recipients && (
                <Form.Control.Feedback type="invalid">
                  {errors.recipients}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                size="lg"
                as="textarea"
                name="message"
                placeholder=""
                value={values.message}
                isInvalid={Boolean(touched.message && errors.message)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.message && (
                <Form.Control.Feedback type="invalid">
                  {errors.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            {/* <Button onClick={handleSubmit} type="submit">
              Submit
            </Button> */}
          </ActionableOffcanvas>
        </Form>
      )}
    </Formik>
  );
};
export default BroadcastForm;
