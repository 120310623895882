import React from "react";
import { Card, Row, Container, Col, Badge, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";
import {
  faSort,
  faSortUp,
  faSquarePollVertical,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullTable from "../../../ui/tables/FullTable";
import { Eye } from "react-feather";

const News = () => {
  const handleClick = (id) => {};
  const cols = [
    {
      Header: "Title",
      accessor: "title",
      Cell: ({ value }) => {
        return <h3>{value}</h3>;
      },
    },
    {
      Header: "Posted by",
      accessor: "author",
    },
    {
      Header: "Posted when",
      accessor: "time_posted",
    },
    {
      Header: "",
      accessor: "action",
      Cell: ({ value }) => {
        return (
          <Button
            onClick={(event) => handleClick(value)}
            variant="outline-primary"
          >
            View
          </Button>
        );
      },
    },
  ];
  const rows = [
    {
      id: 1,
      title: "Bridge Online exchange program",
      author: "Mr. Samwel Walter",
      time_posted: "30/11/2022 10:48 AM",
    },
    {
      id: 2,
      title: "School closing ceremony",
      author: "Mr. Samwel Walter",
      time_posted: "01/12/2022 08:02 AM",
    },
  ];
  return (
    <React.Fragment>
      <Helmet title="News & Announcements" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3 mt-2">News & Announcements</h1>
        <Card>
          <Card.Body>
            <FullTable values={rows} cols={cols} />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default News;
