import React from "react";
import { Card, Row, Container, Col, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";
import StudentCard from "./student_card/StudentCard";
import TeacherCard from "./teacher_card/TeacherCard";
import StaffCard from "./staff_card/StaffCard";
import ParentCard from "./parent_card/ParentCard";
import EventCalendarCard from "./event_calendar_card/EventCalendarCard";
import NoticeBoardCard from "./notice_board_card/NoticeBoardCard";
import QuickActions from "./quick_actions/QuickActions";
import SystemEventsCard from "./system_event_card/SystemEventsCard";

const AdminDash = () => {
  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Dashboard</h1>
        <Row>
          <Col>
            <StudentCard />
          </Col>
          <Col>
            <TeacherCard />
          </Col>
          <Col>
            <StaffCard />
          </Col>
          <Col>
            <ParentCard />
          </Col>
        </Row>
        <QuickActions />
        <Row>
          <Col md={6}>
            <EventCalendarCard />
          </Col>
          <Col md={3}>
            <NoticeBoardCard />
          </Col>
          <Col md={3}>
            <SystemEventsCard />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AdminDash;
