import React from "react";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import GridLayout from "react-grid-layout";
import MappingForm from "./MappingForm";
import MappingResults from "./MappingResults";

const MappingPage = () => {
  return (
    <React.Fragment>
      <Helmet title="Manage subjects" />
      <Container fluid className="p-0">
        <div className="d-flex flex-row justify-content-between">
          <h1 className="h3 mb-3">Mapping</h1>
        </div>
        <Row>
          <Col md={6}>
            <MappingForm />
          </Col>
          <Col>
            <MappingResults />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default MappingPage;
