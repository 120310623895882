import { User } from "react-feather";
import {
  faUser,
  faChalkboardTeacher,
  faPerson,
  faCalendar,
  faMoneyBill,
  faBook,
  faMailBulk,
  faScroll,
} from "@fortawesome/free-solid-svg-icons";
export const quick_actions_list = [
  {
    title: "Edit Subject",
    icon: faUser,
    link: "manage-subjects",
  },
  {
    title: "Add Grade",
    icon: faChalkboardTeacher,
    link: "/admin",
  },
  {
    title: "View Resources",
    icon: faPerson,
    link: "/admin",
  },
  {
    title: "Manage Programs",
    icon: faCalendar,
    link: "/admin",
  },
  {
    title: "Announcements",
    icon: faMoneyBill,
    link: "/admin",
  },
];
