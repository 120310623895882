import React from "react";
import {
  Card,
  Container,
  Button,
  ButtonGroup,
  Row,
  Col,
} from "react-bootstrap";
import FullTable from "../../../ui/tables/FullTable";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import avatar from "../../../assets/img/avatars/avatar-2.jpg";

const StudentDetails = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Helmet title="Students Grade 1" />
      <Container fluid className="p-0">
        <div className="d-flex mb-3 flex-row justify-content-between">
          <h1 className="h3 mb-3">Grade 1</h1>
          <ButtonGroup aria-label="Global subject actions">
            <Button
              onClick={() =>
                navigate("/admin/student-management/enroll-new-student")
              }
              variant="outline-primary"
            >
              Edit Student
            </Button>
            <Button variant="outline-primary">Transfer Student</Button>
            <Button variant="outline-primary">Upload Resources</Button>
          </ButtonGroup>
        </div>
        <Row>
          <Col md={6}>
            <Card>
              <Card.Header>
                <h2 className="h2">Personal Information</h2>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={5}>
                    <img
                      src={avatar}
                      alt="Chris Wood"
                      className="img-fluid img-thumbnail rounded"
                      width="100%"
                      height="152"
                    />
                  </Col>
                  <Col>
                    <p>
                      First name: <strong>Brycen</strong>
                    </p>
                    <p>
                      Middle name: <strong>John</strong>
                    </p>
                    <p>
                      Last name: <strong>Doe</strong>
                    </p>
                    <p>
                      Gender: <strong> Male</strong>
                    </p>
                    <p>Phonenumber: </p>
                    <p>
                      Address: <strong>Dar es Salaam</strong>
                    </p>
                    <p>
                      Nationality: <strong>Tanzanian</strong>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <Card.Header>
                <div className="d-flex flex-row justify-content-between">
                  <h2 className="h2">Parent/Guardian Information</h2>
                  <Button variant="link">Send message</Button>
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <p>
                      First name: <strong>John</strong>
                    </p>
                    <p>
                      Middle name: <strong></strong>
                    </p>
                    <p>
                      Last name: <strong>Doe</strong>
                    </p>
                    <p>
                      Relationship: <strong> Father</strong>
                    </p>
                    <p>
                      Phonenumber: <strong>255 624 723 009</strong>
                    </p>
                    <p>
                      Email: <strong>baraka@aimfirms.com</strong>
                    </p>
                    <p>
                      Address: <strong>Dar es Salaam</strong>
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Card>
              <Card.Header>
                <h2 className="h2">Academic Information</h2>
              </Card.Header>
              <Card.Body>
                <p>
                  Grade level: <strong>1</strong>
                </p>
                <p>
                  Student ID: <strong>126-845-121</strong>
                </p>
                <p>
                  Enrollment date: <strong>3rd September 2022</strong>
                </p>
                <p>
                  Attendance records: <Link to="">View</Link>
                </p>
                <p>
                  Grades/Transcripts: <Link to="">View</Link>
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <Card.Header>
                <h2 className="h2">Health Information</h2>
              </Card.Header>
              <Card.Body>
                <p>
                  Medical condition: <strong>Handsome</strong>
                </p>
                <p>
                  Medications: <strong>Cetrizine</strong>
                </p>
                <h3>Emergency contact</h3>
                <br></br>
                <p>
                  Phone number: <strong>255 624 327 900</strong>
                </p>
                <p>
                  Relationship: <strong>Father</strong>
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default StudentDetails;
