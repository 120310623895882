import React from "react";
import { Table } from "react-bootstrap";

const VerticalFlatSimpleTable = (props) => {
  const { student } = props;
  var j = 100;
  return (
    <React.Fragment>
      <Table className="table table-sm table-borderless" responsive>
        <tbody className="table-hover">
          {Object.entries(student).map(([key, value]) => {
            if (key === "id") {
              return null;
            }
            return (
              <tr key={`${++j}`}>
                <th>{`${key.charAt(0).toUpperCase()}${key.slice(1)}:`}</th>
                <td>{value}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default VerticalFlatSimpleTable;
