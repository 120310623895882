import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();

  return (
    <Formik
      initialValues={{
        reg_no: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        reg_no: Yup.string()
          .max(20)
          .required("Registration number is required"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const useR = await signIn(values.reg_no, values.password);

          // console.log("alert error:", useR);

          // console.log("after awaiting", useR.isSemaAdmin);
          if (useR === "Network Error") {
            // setStatus({ success: false });
            // setErrors({ submit: "Network Error" });
            // setSubmitting(false);
            // console.log("theres a network error");
          }
          if (useR) {
            if (useR.isSemaAdmin) {
              navigate("/admin/welcome");
            } else {
              navigate("/dash");
            }
          }

          // navigate("/admin/admin_manage_sender_ids");
        } catch (error) {
          console.log("errs", error);

          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          if (error.message === "useR is undefined") {
            setErrors({ submit: "Email or password is wrong" });
          } else if (error === "Network Error") {
            setErrors({
              submit: "Netwokr Error! Please check your internet connection.",
            });
          }
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {/* <Alert className="my-3" variant="primary">
            <div className="alert-message">
              Use <strong>demo@bootlab.io</strong> and{" "}
              <strong>unsafepassword</strong> to sign in
            </div>
          </Alert> */}
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}

          <Form.Group className="mb-3">
            <Form.Label>Registration number</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              name="reg_no"
              placeholder="S1224/0021"
              value={values.reg_no}
              isInvalid={Boolean(touched.reg_no && errors.reg_no)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.reg_no && (
              <Form.Control.Feedback type="invalid">
                {errors.reg_no}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              size="lg"
              type="password"
              name="password"
              placeholder="Enter your password"
              value={values.password}
              isInvalid={Boolean(touched.password && errors.password)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.password && (
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            )}
            <small>
              <Link to="/auth/reset-password">Forgot password?</Link>
            </small>
          </Form.Group>

          <div>
            <Form.Check
              type="checkbox"
              id="rememberMe"
              label="Remember me next time"
              defaultChecked
            />
          </div>

          <div className="text-center mt-3">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Sign in
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default SignIn;
