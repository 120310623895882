import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const ReceiptModal = ({ id, show, handleClose }) => {
  const data = {
    student_name: "Baraka Elias Urio",
    grade_level: " Grade 7",
    date: "2023-06-15",
    time: "11:27:17",
    fee_name: "2023-2024 ACADEMIC YEAR",
    amount: "TZS 980,000",
    payment_method: "Cash",
  };

  console.log("RECEIPT MODAL", id);
  return (
    <React.Fragment>
      <Modal show={show} onHide={handleClose} centered size="sm">
        <Modal.Header>
          <div className=" w-100 d-flex flex-column align-items-center">
            <h1>SISI</h1>
            <h2>School name</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-6 d-flex flex-row justify-content-between">
            <div>
              <p>Date</p>
              <h4 className="h4">{data.date}</h4>
            </div>
            <div>
              <p>Time</p>
              <h4 className="h4">{data.time}</h4>
            </div>
          </div>
          <h3 className="mb-6 fw-bold text-uppercase">
            {data.student_name}
            <br></br> {data.grade_level} {data.fee_name}
          </h3>
          <div className="mb-3 d-flex flex-row justify-content-between">
            <div>
              <p>Amount</p>
              <h4 className="h4">{data.amount}</h4>
            </div>
            <div>
              <p>Method</p>
              <h4 className="h4">{data.payment_method}</h4>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ReceiptModal;
