import React from "react";

//Layouts
import DashboardLayout from "./layouts/Dashboard";
import LandingLayout from "./layouts/Landing";
import ParentLayout from "./layouts/Parent";
import DocLayout from "./layouts/Doc";
import AuthLayout from "./layouts/Auth";
import StudentLayout from "./layouts/Student";

//Auth

//Guards
import AuthGuard from "./components/guards/AuthGuard";
import AdminGuard from "./components/guards/AdminGuard";
import GuestGuard from "./components/guards/GuestGuard";

//AMDIN PAGES
import AdminDash from "./pages/admin/dash/AdminDash";

//PARENT PAGES
import ParentDash from "./pages/parents/dash/ParentDash";
import ParentLogin from "./pages/parents/auth/ParentLogin";

//STUDENT PAGES
import StudentDash from "./pages/students/dash/StudentDash";
import StudentRegister from "./pages/auth/StudentRegister";
import Attendance from "./pages/students/attendance/Attendance";
import Reward from "./pages/students/rewards/Reward";
import Results from "./pages/students/results/Results";
import News from "./pages/students/news/News";
import Curriculum from "./pages/students/curriculum/Curriculum";
import School from "./pages/students/school/School";
import SemesterResults from "./pages/students/results/SemesterResults";
import TimeTablePage from "./pages/students/timetable/TimeTablePage";
import SignIn from "./pages/auth/SignIn";
import AcademicCurriculumPage from "./pages/admin/academic_curriculum/AcademicCurriculumPage";
import ManageSubjects from "./pages/admin/academic_curriculum/manage_subjects/ManageSubjects";
import SubjectPage from "./pages/admin/academic_curriculum/manage_subjects/subject_page/SubjectPage";
import ResourcesPage from "./pages/admin/academic_curriculum/resources_page/ResourcesPage";
import MappingPage from "./pages/admin/academic_curriculum/mapping/MappingPage";
import ManageProgramsPage from "./pages/admin/academic_curriculum/manage_programs/ManageProgramsPage";
import ProgramPage from "./pages/admin/academic_curriculum/manage_programs/ProgramPage";
import AnnouncementPage from "./pages/admin/academic_curriculum/announcements/AnnouncementsPage";
import ClassSchedulingPage from "./pages/admin/class_scheduling/ClassSchedulingPage";
import ClassSchedulingGradePage from "./pages/admin/class_scheduling/grade_page/ClassSchedulingGradePage";
import ExamsAssessmentsPage from "./pages/admin/exam_and_assessments/ExamsAssessmentsPage";
import CommunicationsPage from "./pages/admin/communications/CommunicationsPage";
import CommunicatePage from "./pages/admin/communications/CommunicatePage";
import StudentsManagementPage from "./pages/admin/student_management/StudentsManagementPage";
import EnrollStudentPage from "./pages/admin/student_management/EnrollStudentPage";
import ClassDetails from "./pages/admin/student_management/ClassDetails";
import StudentDetails from "./pages/admin/student_management/StudentDetails";
import TeacherManagementPage from "./pages/admin/teacher_management/TeacherManagementPage";
import TeacherDetails from "./pages/admin/teacher_management/TeacherDetails";
import AddTeacherPage from "./pages/admin/teacher_management/AddTeacherPage";
import StaffManagementPage from "./pages/admin/staff_management/StaffManagementPage";
import FinancialManagementPage from "./pages/admin/financial_management.js/FinancialManagementPage";
import FeeDetailsPage from "./pages/admin/financial_management.js/fee_details/FeeDetailsPage";
import GlobalResourcesPage from "./pages/admin/resources_page/GlobalResourcesPage";

const routes = [
  {
    path: "/",
    element: <LandingLayout></LandingLayout>,
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
    ],
  },
  {
    path: "/admin",
    element: <DashboardLayout />,
    children: [
      {
        path: "dashboard",
        element: <AdminDash />,
      },
      {
        path: "academic-curriculum",
        element: <AcademicCurriculumPage />,
      },
      {
        path: "academic-curriculum/manage-subjects",
        element: <ManageSubjects />,
      },
      {
        path: "academic-curriculum/manage-resources",
        element: <ResourcesPage />,
      },
      {
        path: "academic-curriculum/manage-subjects/subject_id",
        element: <SubjectPage />,
      },
      {
        path: "academic-curriculum/mapping",
        element: <MappingPage />,
      },
      {
        path: "academic-curriculum/manage-programs",
        element: <ManageProgramsPage />,
      },
      {
        path: "academic-curriculum/manage-programs/program-id",
        element: <ProgramPage />,
      },
      {
        path: "academic-curriculum/announcements",
        element: <AnnouncementPage />,
      },
      {
        path: "class-scheduling",
        element: <ClassSchedulingPage />,
      },
      {
        path: "class-scheduling/grade_id",
        element: <ClassSchedulingGradePage />,
      },
      {
        path: "exams-and-assesments",
        element: <ExamsAssessmentsPage />,
      },
      {
        path: "communications",
        element: <CommunicationsPage />,
      },
      {
        path: "communications/:audience",
        element: <CommunicatePage />,
      },
      {
        path: "student-management",
        element: <StudentsManagementPage />,
      },
      {
        path: "student-management/enroll-new-student",
        element: <EnrollStudentPage />,
      },
      {
        path: "student-management/:class_id",
        element: <ClassDetails />,
      },
      {
        path: "student-management/:class_id/:student_id",
        element: <StudentDetails />,
      },
      {
        path: "teacher-management",
        element: <TeacherManagementPage />,
      },
      {
        path: "teacher-management/add-teacher",
        element: <AddTeacherPage />,
      },
      {
        path: "teacher-management/:teacher_id",
        element: <TeacherDetails />,
      },
      {
        path: "staff-management",
        element: <StaffManagementPage />,
      },
      {
        path: "financial-management",
        element: <FinancialManagementPage />,
      },
      {
        path: "financial-management/:fee_id",
        element: <FeeDetailsPage />,
      },
      {
        path: "resources",
        element: <GlobalResourcesPage />,
      },
    ],
  },
  {
    path: "/parent",
    element: <ParentLayout />,
    children: [
      {
        path: "",
        element: <ParentDash />,
      },
      {
        path: "sign-in",
        element: <ParentLogin />,
      },
      {
        path: "attendance",
        element: <Attendance />,
      },
      {
        path: "rewards",
        element: <Reward />,
      },
      {
        path: "results",
        element: <Results />,
      },
      {
        path: "results/:id",
        element: <SemesterResults />,
      },
      {
        path: "news",
        element: <News />,
      },
      {
        path: "curriculum",
        element: <Curriculum />,
      },
      {
        path: "my-school",
        element: <School />,
      },
      {
        path: "timetable",
        element: <TimeTablePage />,
      },
    ],
  },
  {
    path: "/student",
    element: <StudentLayout />,
    children: [
      {
        path: "",
        element: <StudentDash />,
      },
      {
        path: "profile",
        element: <StudentRegister />,
      },
      {
        path: "attendance",
        element: <Attendance />,
      },
      {
        path: "rewards",
        element: <Reward />,
      },
      {
        path: "results",
        element: <Results />,
      },
      {
        path: "results/:id",
        element: <SemesterResults />,
      },
      {
        path: "news",
        element: <News />,
      },
      {
        path: "curriculum",
        element: <Curriculum />,
      },
      {
        path: "my-school",
        element: <School />,
      },
      {
        path: "timetable",
        element: <TimeTablePage />,
      },
    ],
  },
];

export default routes;
