import React, { useState } from "react";
import { Row, Container, Card, Col, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import FullTable from "../../../ui/tables/FullTable";
import { RefreshCw } from "react-feather";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import ExamsAssesmentsTable from "./ExamsAssesmentsTable";
import CreateExamAssessmentForm from "./CreateExamAssessmentForm";

const ExamUpcoming = () => {
  const events = [
    {
      id: "1",
      title: "Meeting",
      start: "2023-06-26T10:00:00",
      end: "2023-06-30T12:00:00",
      backgroundColor: "blue",
      borderColor: "blue",
      textColor: "white",
      extendedProps: {
        description: "This is a meeting event",
      },
    },
    {
      id: "2",
      title: "Birthday Party",
      start: "2023-05-26",
      end: "2023-05-27",
      backgroundColor: "green",
      borderColor: "green",
      textColor: "white",
      extendedProps: {
        description: "This is a birthday party event",
      },
    },
  ];
  return (
    <Card>
      <Card.Header>
        <Row>
          <Col>
            <h3>Upcoming Exams</h3>
          </Col>
          <Col md={1}>
            <div className="d-flex flex-row justify-content-end">
              <Button variant="link">
                <RefreshCw size={16} />
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <h1 className="h2">2023 First Semester Final Examinations</h1>
        <p>Start date: 2023-06-26</p>
        <p>End date: 2023-06-30</p>
      </Card.Body>
    </Card>
  );
};
const ExamsAssessmentsPage = () => {
  const [createExamForm, setCreateExamForm] = useState(false);
  const handleClose = () => setCreateExamForm(false);
  return (
    <React.Fragment>
      <Helmet title="Class Scheduling" />
      {createExamForm ? (
        <CreateExamAssessmentForm handleClose={handleClose} />
      ) : null}
      <Container fluid className="p-0">
        <div className="d-flex mb-3 flex-row justify-content-between">
          <h1 className="h3 ">Exams & Assessments</h1>
          <Button
            onClick={() => setCreateExamForm(true)}
            variant="outline-primary"
          >
            New Exam/Assesment
          </Button>
        </div>
        <Row>
          <Col md={5}>
            <ExamUpcoming />
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <ExamsAssesmentsTable />
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ExamsAssessmentsPage;
