import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { RefreshCw } from "react-feather";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const EventCalendarCard = () => {
  const events = [
    {
      id: "1",
      title: "Meeting",
      start: "2023-05-24T10:00:00",
      end: "2023-05-24T12:00:00",
      backgroundColor: "blue",
      borderColor: "blue",
      textColor: "white",
      extendedProps: {
        description: "This is a meeting event",
      },
    },
    {
      id: "2",
      title: "Birthday Party",
      start: "2023-05-26",
      end: "2023-05-27",
      backgroundColor: "green",
      borderColor: "green",
      textColor: "white",
      extendedProps: {
        description: "This is a birthday party event",
      },
    },
  ];
  return (
    <Card>
      <Card.Header>
        <Row>
          <Col>
            <h3>Event Calendar</h3>
          </Col>
          <Col md={1}>
            <div className="d-flex flex-row justify-content-end">
              <Button variant="link">
                <RefreshCw size={16} />
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          height="32em"
          themeSystem="bootstrap5"
          headerToolbar={{
            left: "prev today",
            center: "title",
            right: "next",
          }}
          events={events}
        />
      </Card.Body>
    </Card>
  );
};
export default EventCalendarCard;
