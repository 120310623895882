import React, { useState } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  ListGroup,
  Button,
  Offcanvas,
  ButtonGroup,
} from "react-bootstrap";
import { Link2 } from "react-feather";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import QuickActions from "./quick_actions/QuickActions";
import Analytics from "./analystics/Analytics";
import CurrentGrade from "./current_grade/CurrentGrade";
import ActionableOffcanvas from "../../../../../ui/actionables/offcanvas/ActionableOffcanvas";
import EditSubjectForm from "./forms/EditSubjectForm";
import AddRemoveGradesForm from "./forms/AddRemoveGradesForm";
import UploadResourceForm from "./forms/UploadResourceForm";

const SubjectPage = () => {
  const [currentGrade, setCurrentGrade] = useState("3");
  const [editSubject, setEditSubject] = useState(false);
  const [manageGrade, setManageGrade] = useState(false);
  const [uploadResource, setUploadResource] = useState(false);
  const handleClose = () => {
    setEditSubject(false);
    setManageGrade(false);
    setUploadResource(false);
  };
  const grades_taught = [
    {
      id: "3",
      name: "Grade 3",
    },
    {
      id: "4",
      name: "Grade 4",
    },
    {
      id: "5",
      name: "Grade 5",
    },
    {
      id: "6",
      name: "Grade 6",
    },
    {
      id: "7",
      name: "Grade 7",
    },
    {
      id: "8",
      name: "Form 1",
    },
    {
      id: "9",
      name: "Form 2",
    },
    {
      id: "10",
      name: "Form 3",
    },
    {
      id: "11",
      name: "Form 4",
    },
  ];

  return (
    <React.Fragment>
      {editSubject ? <EditSubjectForm handleClose={handleClose} /> : null}
      {manageGrade ? <AddRemoveGradesForm handleClose={handleClose} /> : null}
      {uploadResource ? <UploadResourceForm handleClose={handleClose} /> : null}
      <Helmet title="Academic Curriculum" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Basic Mathematics</h1>
        {/* <Row>
          <QuickActions />
        </Row> */}
        <Row>
          <Card>
            <Card.Header>
              <h1 className="h3 mb-3">Basic Mathematics</h1>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={7}>
                  <h2 className="h4 mb-2">Description</h2>
                  <p className="mb-5">
                    Basic Mathematics is a foundational subject that covers
                    essential mathematical concepts and skills. It provides
                    students with a solid understanding of fundamental
                    mathematical principles and lays the groundwork for further
                    mathematical learning. This subject focuses on building a
                    strong numerical foundation, developing problem-solving
                    abilities, and enhancing critical thinking skills.
                  </p>
                </Col>
                <Col>
                  <div className="d-flex flex-row justify-content-end">
                    <ButtonGroup aria-label="Global subject actions">
                      <Button
                        onClick={(e) => setEditSubject(true)}
                        variant="outline-primary"
                      >
                        Edit Subject
                      </Button>
                      <Button
                        onClick={(e) => setManageGrade(true)}
                        variant="outline-primary"
                      >
                        Add/Remove Grade
                      </Button>
                      <Button
                        onClick={(e) => setUploadResource(true)}
                        variant="outline-primary"
                      >
                        Upload Resources
                      </Button>
                    </ButtonGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <h1 className="h3 mb-3">Grades Taught</h1>

                <div style={{ overflowX: "auto" }}>
                  <ListGroup className="mb-3" horizontal>
                    {grades_taught.map((grade) => {
                      return (
                        <ListGroup.Item
                          action
                          onClick={() => setCurrentGrade(grade.id)}
                          key={grade.id}
                        >
                          {grade.name}
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Row>

        <CurrentGrade />

        <Row>
          <h2 className="h2 mb-2">Analytics</h2>
          <Analytics />
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default SubjectPage;
