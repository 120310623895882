import React, { useState } from "react";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import FullTable from "../../../../ui/tables/FullTable";
import { Link } from "react-router-dom";
import GridLayout from "react-grid-layout";
import ResourceCard from "../../../../ui/cards/ResourceCard";
import UploadResourceForm from "../manage_subjects/subject_page/forms/UploadResourceForm";

const ResourcesPage = () => {
  const [uploadResource, setUploadResource] = useState(false);
  const handleClose = () => {
    setUploadResource(false);
  };
  const resources = [
    {
      id: 1,
      title: "The Secret to Trigonometry",
      description:
        "This is a descriptiion of Title 1, I hope i am very descriptive",
      link: "https://google.com",
    },
    {
      id: 2,
      title: "Title 1",
      description:
        "This is a descriptiion of Title 1, I hope i am very descriptive",
      link: "https://google.com",
    },
    {
      id: 3,
      title: "Title 1",
      description:
        "This is a descriptiion of Title 1, I hope i am very descriptive",
      link: "https://google.com",
    },
    {
      id: 4,
      title: "The Secret to Trigonometry",
      description:
        "This is a descriptiion of Title 1, I hope i am very descriptive",
      link: "https://google.com",
    },
    {
      id: 5,
      title: "The Secret to Trigonometry",
      description:
        "This is a descriptiion of Title 1, I hope i am very descriptive",
      link: "https://google.com",
    },
    {
      id: 6,
      title: "The Secret to Trigonometry",
      description:
        "This is a descriptiion of Title 1, I hope i am very descriptive",
      link: "https://google.com",
    },
    {
      id: 7,
      title: "The Secret to Trigonometry",
      description:
        "This is a descriptiion of Title 1, I hope i am very descriptive",
      link: "https://google.com",
    },
    {
      id: 8,
      title: "The Secret to Trigonometry",
      description:
        "This is a descriptiion of Title 1, I hope i am very descriptive",
      link: "https://google.com",
    },
    {
      id: 9,
      title: "The Secret to Trigonometry",
      description:
        "This is a descriptiion of Title 1, I hope i am very descriptive",
      link: "https://google.com",
    },
    {
      id: 10,
      title: "The Secret to Trigonometry",
      description:
        "This is a descriptiion of Title 1, I hope i am very descriptive",
      link: "https://google.com",
    },
    {
      id: 11,
      title: "The Secret to Trigonometry",
      description:
        "This is a descriptiion of Title 1, I hope i am very descriptive",
      link: "https://google.com",
    },
  ];
  const columns = [
    {
      Header: "Code",
      accessor: "code",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Department",
      accessor: "department",
    },
    {
      Header: "Success rate",
      accessor: "success_rate",
      Cell: ({ value }) => {
        if (value >= 80) {
          return <p className="text-primary">{`${value}%`}</p>;
        } else if (value >= 65) {
          return <p className="text-success">{`${value}%`}</p>;
        } else if (value >= 50) {
          return <p className="text-warning">{`${value}%`}</p>;
        } else if (value < 50) {
          return <p className="text-danger">{`${value}%`}</p>;
        } else {
          return <p>not available</p>;
        }
      },
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value }) => (
        <div className="d-flex flex-row">
          <Link
            className="pe-3"
            to="/admin/academic-curriculum/manage-subjects/subject_id"
          >
            View
          </Link>
          <Link to="/admin">Delete</Link>
        </div>
      ),
    },
  ];
  var x = 0;

  const value = [
    {
      id: "1",
      name: "Basic Mathematics",
      code: "BAM233",
      department: "Mathematics",
      success_rate: 80,
    },
    {
      id: "2",
      name: "English",
      code: "ENG233",
      department: "Language",
      success_rate: 90,
    },
    {
      id: "3",
      name: "Kiswahili",
      code: "KWH233",
      department: "Language",
      success_rate: 78,
    },
    {
      id: "4",
      name: "Physics",
      code: "PHY233",
      department: "Science",
      success_rate: 89,
    },
    {
      id: "5",
      name: "Chemistry",
      code: "CHM233",
      department: "Science",
      success_rate: 29,
    },
    {
      id: "6",
      name: "Biology",
      code: "BIO233",
      department: "Science",
      success_rate: 52,
    },
  ];
  const layout = resources.map((card, index) => ({
    i: card.id.toString(),
    x: index % 5,
    y: Math.floor(index / 2),
    w: 1,
    h: 2,
  }));
  console.log("Grid layout: ", layout);
  return (
    <React.Fragment>
      <Helmet title="Manage subjects" />
      {uploadResource ? <UploadResourceForm handleClose={handleClose} /> : null}

      <Container fluid className="p-0">
        <div className="d-flex flex-row justify-content-between">
          <h1 className="h3 mb-3">Resource & Materials</h1>
          <Button
            onClick={(e) => setUploadResource(true)}
            className="mb-3"
            variant="outline-primary"
          >
            Upload material
          </Button>
        </div>
        <GridLayout
          className="layout"
          layout={layout}
          cols={5}
          rowHeight={100}
          width={1200}
        >
          {resources.map((res) => (
            <div key={res.id}>
              <ResourceCard
                title={res.title}
                desc={res.description}
                link={res.link}
              />
            </div>
          ))}
        </GridLayout>
      </Container>
    </React.Fragment>
  );
};

export default ResourcesPage;
