import React from "react";
import { Bar } from "react-chartjs-2";
import usePalette from "../../../../../../hooks/usePalette";
import { Card } from "react-bootstrap";

const AverageScoresBarChart = () => {
  const palette = usePalette();

  const data = {
    labels: [
      "Grade 3",
      "Grade 4",
      "Grade 5",
      "Grade 6",
      "Grade 7",
      "Form 1",
      "Form 2",
      "Form 3",
      "Form 4",
    ],
    datasets: [
      {
        label: "Last year",
        backgroundColor: palette.primary,
        borderColor: palette.primary,
        hoverBackgroundColor: palette.primary,
        hoverBorderColor: palette.primary,
        data: [54, 67, 41, 55, 62, 45, 55, 73, 60],
        barPercentage: 0.75,
        categoryPercentage: 0.5,
      },
      {
        label: "This year",
        backgroundColor: "#E8EAED",
        borderColor: "#E8EAED",
        hoverBackgroundColor: "#E8EAED",
        hoverBorderColor: "#E8EAED",
        data: [69, 66, 24, 48, 52, 51, 44, 53, 62],
        barPercentage: 0.75,
        categoryPercentage: 0.5,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: false,
          ticks: {
            stepSize: 20,
          },
        },
      ],
      xAxes: [
        {
          stacked: false,
          gridLines: {
            color: "transparent",
          },
        },
      ],
    },
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h2">Average scores</Card.Title>
        <h6 className="card-subtitle text-muted">
          Show the average scores achieved by students in the subject. This can
          include the average score for each assessment type (e.g., exams,
          quizzes) or an overall average score.
        </h6>
      </Card.Header>
      <Card.Body>
        <div className="chart">
          <Bar data={data} options={options} />
        </div>
      </Card.Body>
    </Card>
  );
};
export default AverageScoresBarChart;
