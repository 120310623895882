import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Alert } from "react-bootstrap";
import TinyCustomCheckbox from "../../../../ui/custom_checkbox/TinyCustomCheckbox";
import ActionableOffcanvas from "../../../../ui/actionables/offcanvas/ActionableOffcanvas";

const FeeSetupForm = ({ handleClose, initVals }) => {
  const school_grades = [
    {
      id: "1",
      name: "Grade 1",
    },
    {
      id: "2",
      name: "Grade 2",
    },
    {
      id: "3",
      name: "Grade 3",
    },
    {
      id: "4",
      name: "Grade 4",
    },
    {
      id: "5",
      name: "Grade 5",
    },
    {
      id: "6",
      name: "Grade 6",
    },
    {
      id: "7",
      name: "Grade 7",
    },
    {
      id: "8",
      name: "Form 1",
    },
    {
      id: "9",
      name: "Form 2",
    },
    {
      id: "10",
      name: "Form 3",
    },
    {
      id: "11",
      name: "Form 4",
    },
  ];
  return (
    <Formik
      initialValues={{
        ...initVals,
      }}
      validationSchema={Yup.object().shape({
        fee_title: Yup.string().required("Title of the fee is required!"),
        amount: Yup.number("Must only contain numbers").required(
          "Amount is required"
        ),
        due_date: Yup.date("Must be a valid date").required(
          "Due date is required"
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          console.log("FEE SETUP FORM: ", values);
        } catch (e) {}
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ActionableOffcanvas
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            action="Fee setup"
            title="Create a New Fee"
          >
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Fee Title</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="fee_title"
                placeholder=""
                value={values.fee_title}
                isInvalid={Boolean(touched.fee_title && errors.fee_title)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.fee_title && (
                <Form.Control.Feedback type="invalid">
                  {errors.fee_title}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Amount to be paid</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="amount"
                placeholder=""
                value={values.amount}
                isInvalid={Boolean(touched.amount && errors.amount)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.amount && (
                <Form.Control.Feedback type="invalid">
                  {errors.amount}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Due date</Form.Label>
              <Form.Control
                size="lg"
                type="date"
                name="due_date"
                placeholder=""
                value={values.due_date}
                isInvalid={Boolean(touched.due_date && errors.due_date)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.due_date && (
                <Form.Control.Feedback type="invalid">
                  {errors.due_date}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Program</Form.Label>
              <Form.Select
                size="lg"
                type=""
                name="program"
                placeholder=""
                value={values.program}
                isInvalid={Boolean(touched.program && errors.program)}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option></option>
                <option>Art Class</option>
                <option>Swimming Lessons</option>
              </Form.Select>
              {!!touched.program && (
                <Form.Control.Feedback type="invalid">
                  {errors.program}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>Select the grades</Form.Label>
              <div className="d-flex flex-row flex-wrap">
                {school_grades.map((grade) => (
                  <TinyCustomCheckbox
                    key={grade.id}
                    handleChange={handleChange}
                    val={grade.name}
                    name="for_grades"
                    label={grade.name}
                  />
                ))}
              </div>
              {!!touched.for_grades && (
                <Form.Control.Feedback type="invalid">
                  {errors.for_grades}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </ActionableOffcanvas>
        </Form>
      )}
    </Formik>
  );
};

export default FeeSetupForm;
