import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import QuickActions from "./quick_actions/QuickActions";
import CurriculumOverview from "./overview/CurriculumOverview";

const AcademicCurriculumPage = () => {
  return (
    <React.Fragment>
      <Helmet title="Academic Curriculum" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Academic Curriculum</h1>
        <Row>
          <QuickActions />
        </Row>
        <Row>
          <CurriculumOverview />
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default AcademicCurriculumPage;
