import React, { useState } from "react";
import { Form } from "react-bootstrap";

const TinyCustomCheckbox = ({ handleChange, name, label, val, disabled }) => {
  const [checked, setChecked] = useState(false);
  console.log("Disabled: ", disabled);

  return (
    <div className="box-xs">
      <input
        type="checkbox"
        onChange={handleChange}
        disabled={disabled}
        value={val}
        name={name}
        id=""
      />
      <div className="boxContent">
        <p>{label}</p>
      </div>
    </div>
  );
};
export default TinyCustomCheckbox;
