import React from "react";
import { Card } from "react-bootstrap";
import FlatSimpleTable from "../../../../ui/tables/FlatSimpleTable";

const OutstandingFees = () => {
  const cols = [
    {
      Header: "Fee name",
      accessor: "fee_name",
    },
    {
      Header: "Total amount",
      accessor: "total_amount",
    },
  ];
  const vals = [
    {
      fee_name: "Tuition Fees",
      total_amount: "980000",
    },
    {
      fee_name: "Exam Fees",
      total_amount: "80000",
    },
    {
      fee_name: "Art Class Fees",
      total_amount: "980000",
    },
    {
      fee_name: "Graduation Fees",
      total_amount: "980000",
    },
  ];
  return (
    <Card>
      <Card.Header>
        <h2 className="h2">Outstanding Fees</h2>
      </Card.Header>
      <Card.Body>
        <FlatSimpleTable values={vals} cols={cols} />
      </Card.Body>
    </Card>
  );
};

export default OutstandingFees;
