import { User } from "react-feather";
import {
  faUser,
  faChalkboardTeacher,
  faPerson,
  faCalendar,
  faMoneyBill,
  faBook,
  faMailBulk,
  faScroll,
} from "@fortawesome/free-solid-svg-icons";
export const quick_actions_list = [
  {
    title: "Parents",
    icon: faUser,
    link: "parents",
  },
  {
    title: "Teachers",
    icon: faChalkboardTeacher,
    link: "teachers",
  },
  {
    title: "Students",
    icon: faPerson,
    link: "students",
  },
  {
    title: "Staff",
    icon: faCalendar,
    link: "staff",
  },
];
