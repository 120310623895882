import React from "react";
import { Card, Row, Container, Col, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";
import {
  faSort,
  faSortUp,
  faSquarePollVertical,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimeTable from "react-timetable-events";

const TimeTablePage = () => {
  const schedule1 = {
    monday: [
      {
        id: 1,
        name: "Mathematics",
        type: "custom",
        startTime: new Date("2022-12-01T11:30:00"),
        endTime: new Date("2022-12-01T13:30:00"),
      },
      {
        id: 2,
        name: "English",
        type: "custom",
        startTime: new Date("2022-12-01T13:40:00"),
        endTime: new Date("2022-12-01T15:30:00"),
      },
      {
        id: 3,
        name: "Free period",
        type: "custom",
        startTime: new Date("2022-12-01T15:35:00"),
        endTime: new Date("2022-12-01T16:30:00"),
      },
    ],
    tuesday: [
      {
        id: 1,
        name: "Mathematics",
        type: "custom",
        startTime: new Date("2022-12-01T11:30:00"),
        endTime: new Date("2022-12-01T13:30:00"),
      },
      {
        id: 2,
        name: "English",
        type: "custom",
        startTime: new Date("2022-12-01T13:40:00"),
        endTime: new Date("2022-12-01T15:30:00"),
      },
      {
        id: 3,
        name: "Free period",
        type: "custom",
        startTime: new Date("2022-12-01T15:35:00"),
        endTime: new Date("2022-12-01T16:30:00"),
      },
    ],
    wednesday: [
      {
        id: 1,
        name: "Mathematics",
        type: "custom",
        startTime: new Date("2022-12-01T11:30:00"),
        endTime: new Date("2022-12-01T13:30:00"),
      },
      {
        id: 2,
        name: "English",
        type: "custom",
        startTime: new Date("2022-12-01T13:40:00"),
        endTime: new Date("2022-12-01T15:30:00"),
      },
      {
        id: 3,
        name: "Free period",
        type: "custom",
        startTime: new Date("2022-12-01T15:35:00"),
        endTime: new Date("2022-12-01T16:30:00"),
      },
    ],
    thursday: [
      {
        id: 1,
        name: "Mathematics",
        type: "custom",
        startTime: new Date("2022-12-01T11:30:00"),
        endTime: new Date("2022-12-01T13:30:00"),
      },
      {
        id: 2,
        name: "English",
        type: "custom",
        startTime: new Date("2022-12-01T13:40:00"),
        endTime: new Date("2022-12-01T15:30:00"),
      },
      {
        id: 3,
        name: "Free period",
        type: "custom",
        startTime: new Date("2022-12-01T15:35:00"),
        endTime: new Date("2022-12-01T16:30:00"),
      },
    ],
    friday: [
      {
        id: 1,
        name: "Mathematics",
        type: "custom",
        startTime: new Date("2022-12-01T08:00:00"),
        endTime: new Date("2022-12-01T10:30:00"),
      },
      {
        id: 2,
        name: "English",
        type: "custom",
        startTime: new Date("2022-12-01T13:40:00"),
        endTime: new Date("2022-12-01T15:30:00"),
      },
      {
        id: 3,
        name: "Free period",
        type: "custom",
        startTime: new Date("2022-12-01T15:35:00"),
        endTime: new Date("2022-12-01T16:30:00"),
      },
    ],
  };

  const initialSchedule = [
    {
      id: "1",
      name: "Mathematics",
      day: 1,
      startTime: "09:00",
      endTime: "10:30",
    },
  ];
  return (
    <React.Fragment>
      <Helmet title="TimeTable" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Timetable</h1>
      </Container>
      <TimeTable
        className="daily-schedule-card-timetable"
        events={initialSchedule}
        hoursInterval={{
          from: 8,
          to: 17,
        }}
        style={{ height: "400px" }}
      />
    </React.Fragment>
  );
};

export default TimeTablePage;
