import React from "react";
import { Doughnut } from "react-chartjs-2";

import { Card } from "react-bootstrap";

import usePalette from "../../../../hooks/usePalette";

const WithdrawnStudentsDonught = () => {
  const palette = usePalette();

  const data = {
    labels: ["Active", "Other"],
    datasets: [
      {
        data: [1, 269],
        backgroundColor: [palette.warning, "#E8EAED"],
        borderColor: "transparent",
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 65,
    legend: {
      display: false,
    },
  };

  return (
    <div className="chart chart-sm">
      <Doughnut data={data} options={options} />
    </div>
  );
};

export default WithdrawnStudentsDonught;
