import React, { useState } from "react";
import ActionableOffcanvas from "../../../../ui/actionables/offcanvas/ActionableOffcanvas";
import { Formik } from "formik";
import { Form, Alert } from "react-bootstrap";
import * as Yup from "yup";
const CreateAnnouncementForm = ({ handleClose }) => {
  return (
    <Formik
      initialValues={{
        title: "",
        message: "",
        author: "",
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Provide the title of the Announcement"),
        message: Yup.string().required("Provide the message"),
        author: Yup.string().max(30).required("Author is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          console.log("EDIT SUBJECT FORM VALUES: ", values);
        } catch (e) {}
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ActionableOffcanvas
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            action="Make a New Announcement"
            title=""
          >
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Announcement Title</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="title"
                placeholder=""
                value={values.title}
                isInvalid={Boolean(touched.title && errors.title)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.title && (
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                size="lg"
                as="textarea"
                name="message"
                placeholder=""
                value={values.message}
                isInvalid={Boolean(touched.message && errors.message)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.message && (
                <Form.Control.Feedback type="invalid">
                  {errors.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </ActionableOffcanvas>
        </Form>
      )}
    </Formik>
  );
};
export default CreateAnnouncementForm;
