import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const parentstudentsApi = createApi({
  reducerPath: "parentstudentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "http://127.0.0.1:8000",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().authSlice.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["parentStudents"],
  endpoints: (builder) => ({
    getAllParentStudents: builder.query({
      query: () => "parent-students",
      transformErrorResponse: (response, meta, arg) => response.status,
      providesTags: ["parentStudents"],
    }),
  }),
});

export const { useGetAllParentStudentsQuery } = parentstudentsApi;
