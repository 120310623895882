import React from "react";
const SystemMessages = () => {
  const messages = [
    {
      title: "Scheduled maintanance",
      description: "There is a scheduled maintance at midnight",
      status: "success",
    },
    {
      title: "Task failed",
      description:
        "The long running background task failed to execute. This was due to overloading of the servers",
      status: "fail",
    },
    {
      title: "Mailing completed",
      description:
        "The long running background task failed to execute. This was due to overloading of the servers",
      status: "fail",
    },
  ];
  const Message = ({ msg }) => {
    var messageStatus = "";
    switch (msg.status) {
      case "fail":
        messageStatus = "#d9534f";
        break;
      case "info":
        messageStatus = "#1F9BCF";
        break;
      case "success":
        messageStatus = "#4BBF73";
        break;
      default:
        messageStatus = "#6c757d";
        break;
    }
    return (
      <div className="p-1 d-flex flex-row pb-2">
        <div
          style={{ backgroundColor: messageStatus }}
          className="message-status-box"
        ></div>
        <div className="p-1">
          <h3>{msg.title}</h3>
          <p>{msg.description}</p>
        </div>
      </div>
    );
  };
  return (
    <div className="d-flex flex-column">
      {messages.map((message) => (
        <Message msg={message} />
      ))}
    </div>
  );
};
export default SystemMessages;
