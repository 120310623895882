import React, { useState } from "react";
import {
  Card,
  Row,
  Container,
  Col,
  Alert,
  Form,
  Badge,
  Button,
  Modal,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users, Mail, MessageSquare } from "react-feather";
import { FieldArray, Formik } from "formik";
import * as Yup from "yup";

const AddTeacherPage = () => {
  return (
    <React.Fragment>
      <Helmet title="Add a Teacher" />
      <Container fluid className="p-0">
        <div className="d-flex mb-3 flex-row justify-content-between">
          <h1 className="h3 ">Add New Teacher</h1>
        </div>
        <Card>
          <Card.Header>
            <h2>Add A New Teacher</h2>
          </Card.Header>
          <Card.Body>
            <Formik
              initialValues={{
                personal_information: {
                  first_name: "",
                  middle_name: "",
                  last_name: "",
                  gender: "",
                  nationality: "",
                  date_of_birth: "",
                },
                contact_information: {
                  email: "",
                  phone_number: "",
                  emergency_phone_number: "",
                  address: "",
                },
                qualifications: {
                  highest_qualification: "",
                  title: "",
                  institution_name: "",
                  subjects_specialized: [],
                },

                employment_information: {
                  employment_id: "",
                  status: "",
                  department: "",
                },
                additional_details: {
                  interests: [],
                  special_needs: [],
                },
              }}
              validationSchema={Yup.object().shape({
                personal_information: Yup.object().shape({
                  first_name: Yup.string().required("First name is required!"),
                  middle_name: Yup.string(),
                  last_name: Yup.string().required("Last name is required!"),
                  gender: Yup.string().required("Gender is required!"),
                  nationality: Yup.string().required(
                    "Nationality is required!"
                  ),
                  date_of_birth: Yup.date().required(
                    "Date of birth is required!"
                  ),
                }),
                contact_information: Yup.object().shape({
                  email: Yup.string().email("Must be a valid email!"),
                  phone_number: Yup.string(),
                  emergency_phone_number: Yup.string().required(
                    "Emergency phone number is required!"
                  ),
                  address: Yup.string().required("Address is required!"),
                }),
                qualifications: Yup.object().shape({
                  highest_qualification: Yup.string().required(
                    "Specify Highest Qualification obtained"
                  ),
                  title: Yup.string().required(
                    "Specify the title of the highest qualification obtained!"
                  ),
                  institution_name: Yup.object().shape({
                    email: Yup.string().email(
                      "Name of the Institution is required!"
                    ),
                    subjects_specialized: Yup.array().min(
                      1,
                      "Must specialize in at least one subject!"
                    ),
                  }),
                }),

                employment_information: Yup.object().shape({
                  employment_id: Yup.string().required(
                    "Employment ID is required"
                  ),
                  status: Yup.string().required(
                    "Employment status is required!"
                  ),
                  department: Yup.string().required("Department is required!"),
                }),
                additional_details: Yup.object().shape({
                  interests: Yup.array(),
                  special_needs: Yup.array(),
                }),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                try {
                  console.log("ADD TEACHER FORM", values);
                } catch (error) {
                  setSubmitting(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}
                  <h3 className="mb-4">Teachers's Personal Information</h3>
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>First name</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.personal_information.first_name}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.first_name
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.first_name"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.first_name}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Middle name</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.personal_information.middle_name}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.middle_name
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.middle_name"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.middle_name}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Last name</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.personal_information.last_name}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.last_name
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.last_name"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.last_name}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mb-6">
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Nationality</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.personal_information.nationality}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.nationality
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.nationality"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.nationality}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Gender</Form.Label>
                        <Form.Select
                          size="lg"
                          value={values.personal_information.middle_name}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.gender
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.gender"
                        >
                          <option></option>
                          <option value="male">Male</option>
                          <option value="femaile">Female</option>
                        </Form.Select>
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.gender}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Date of birth</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.personal_information.date_of_birth}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.date_of_birth
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="date"
                          name="personal_information.date_of_birth"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.date_of_birth}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <h3 className="mb-4">Teacher's Contact Information</h3>
                  <Row className="mb-6">
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Phone number</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.personal_information.phone_number}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.phone_number
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.phone_number"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.phone_number}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Emergency Phone number</Form.Label>
                        <Form.Control
                          size="lg"
                          value={
                            values.personal_information.emergency_phone_number
                          }
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.emergency_phone_number
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.emergency_phone_number"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.emergency_phone_number}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.personal_information.address}
                          isInvalid={Boolean(
                            touched.personal_information &&
                              errors.personal_information.address
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="personal_information.address"
                        />
                        {!!touched.personal_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.personal_information.address}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <h3 className="mb-4">Qualifications</h3>
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Highest Obtained Qualification</Form.Label>
                        <Form.Select
                          size="lg"
                          value={values.qualifications.highest_qualification}
                          isInvalid={Boolean(
                            touched.qualifications &&
                              errors.qualifications["highest_qualification"]
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="qualificationos.highest_qualification"
                        >
                          <option></option>
                          <option value="diploma">Diploma</option>
                          <option value="degree">Degree</option>
                          <option value="masters">Masters</option>
                        </Form.Select>
                        {!!touched.qualifications && (
                          <Form.Control.Feedback type="invalid">
                            {errors.qualifications.qualifications}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Title of Qualification</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.qualifications.title}
                          isInvalid={Boolean(
                            touched.qualifications &&
                              errors.qualifications.title
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="qualifications.title"
                        />
                        {!!touched.qualifications && (
                          <Form.Control.Feedback type="invalid">
                            {errors.qualifications.title}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>Name of Institution Obtained</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.qualifications.institution_name}
                          isInvalid={Boolean(
                            touched.qualifications &&
                              errors.qualifications.institution_name
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="qualifications.institution_name"
                        />
                        {!!touched.qualifications && (
                          <Form.Control.Feedback type="invalid">
                            {errors.qualifications.institution_name}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Form.Label>Subjects specialized</Form.Label>
                      <FieldArray
                        name="qualifications.subjects_specialized"
                        render={(arrayHelpers) => (
                          <Row>
                            {values.qualifications.subjects_specialized &&
                            values.qualifications.subjects_specialized.length >
                              0 ? (
                              <div>
                                {values.qualifications.subjects_specialized.map(
                                  (condition, index) => (
                                    <Col key={index} md={2}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>Subject name</Form.Label>
                                        <Form.Control
                                          name={`qualifications.subjects_specialized[${index}]`}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          type="text"
                                          size="lg"
                                          value={condition}
                                          isInvalid={Boolean(
                                            touched.health_information &&
                                              errors.qualifications
                                                .subjects_specialized[index]
                                          )}
                                        />
                                        {!!touched.qualifications && (
                                          <Form.Control.Feedback type="invalid">
                                            {
                                              errors.qualifications
                                                .subjects_specialized[index]
                                            }
                                          </Form.Control.Feedback>
                                        )}
                                      </Form.Group>
                                      <Button
                                        variant="outline-warning"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        } // remove a friend from the list
                                      >
                                        -
                                      </Button>

                                      <Button
                                        variant="outline-primary"
                                        onClick={() =>
                                          arrayHelpers.insert(index, "")
                                        } // insert an empty string at a position
                                      >
                                        +
                                      </Button>
                                    </Col>
                                  )
                                )}
                              </div>
                            ) : (
                              <Button
                                variant="outline-primary"
                                onClick={() => arrayHelpers.push("")}
                              >
                                Add Subjects
                              </Button>
                            )}
                          </Row>
                        )}
                      />
                    </Col>
                  </Row>
                  <h3 className="mb-4">Employment Information</h3>
                  <Row className="mb-6">
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Employee ID</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.employment_information.employment_id}
                          isInvalid={Boolean(
                            touched.employment_information &&
                              errors.employment_information.employment_id
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="employment_information.employment_id"
                        />
                        {!!touched.employment_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.employment_information.employment_id}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Employment status</Form.Label>
                        <Form.Select
                          size="lg"
                          value={values.employment_information.status}
                          isInvalid={Boolean(
                            touched.employment_information &&
                              errors.employment_information.status
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="employment_information.status"
                        >
                          <option></option>
                          <option value="full_time">Ful Time</option>
                          <option value="part_time">Part Time</option>
                          <option value="field_time">Field/Practical</option>
                          <option value="contract">Contractual</option>
                        </Form.Select>
                        {!!touched.employment_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.employment_information.status}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={3}>
                      <Form.Group className="mb-3">
                        <Form.Label>Department</Form.Label>
                        <Form.Control
                          size="lg"
                          value={values.employment_information.department}
                          isInvalid={Boolean(
                            touched.employment_information &&
                              errors.employment_information.department
                          )}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type="text"
                          name="employment_information.department"
                        />
                        {!!touched.employment_information && (
                          <Form.Control.Feedback type="invalid">
                            {errors.employment_information.department}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="d-flex flex-row justify-content-end">
                    <Button
                      className="px-5 py-3"
                      type="submit"
                      variant="primary"
                    >
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default AddTeacherPage;
