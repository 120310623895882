import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Radio } from "react-feather";

const LiveBroadCastCard = () => {
  return (
    <Card>
      <Card.Header>
        <h2>
          <Radio />
          Live Broadcast
        </h2>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={9}>
            <h3>Fundamental Physics</h3>
            <h4>Mr. Rustus Lyimo</h4>
          </Col>
          <Col>
            <Button size="lg" variant="success">
              Join
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default LiveBroadCastCard;
