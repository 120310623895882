import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Alert } from "react-bootstrap";
import TinyCustomCheckbox from "../../../../ui/custom_checkbox/TinyCustomCheckbox";
import ActionableOffcanvas from "../../../../ui/actionables/offcanvas/ActionableOffcanvas";

const FeePaymentForm = ({ handleClose, initVals }) => {
  console.log("FEE PAYMENT FORM", initVals);
  const school_grades = [
    {
      id: "1",
      name: "Grade 1",
    },
    {
      id: "2",
      name: "Grade 2",
    },
    {
      id: "3",
      name: "Grade 3",
    },
    {
      id: "4",
      name: "Grade 4",
    },
    {
      id: "5",
      name: "Grade 5",
    },
    {
      id: "6",
      name: "Grade 6",
    },
    {
      id: "7",
      name: "Grade 7",
    },
    {
      id: "8",
      name: "Form 1",
    },
    {
      id: "9",
      name: "Form 2",
    },
    {
      id: "10",
      name: "Form 3",
    },
    {
      id: "11",
      name: "Form 4",
    },
  ];
  return (
    <Formik
      initialValues={{ ...initVals }}
      validationSchema={Yup.object().shape({
        fee_id: Yup.string().required("Fee ID is required!"),
        payment_method: Yup.string().required("Payment method is required!"),
        amount: Yup.number("Must only contain numbers").required(
          "Amount is required"
        ),
        payment_date: Yup.date("Must be a valid date").required(
          "Payment date is required"
        ),
        transaction_id: Yup.string().required("Transaction ID is required"),
        student_id: Yup.string().required("Student ID is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          console.log("FEE SETUP FORM: ", values);
        } catch (e) {}
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ActionableOffcanvas
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            action="Fee Payment"
            title="2023-2024 ACADEMIC YEAR TUITION FEE"
          >
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Payment method</Form.Label>
              <Form.Select
                size="lg"
                type=""
                name="payment_method"
                placeholder=""
                value={values.payment_method}
                isInvalid={Boolean(
                  touched.payment_method && errors.payment_method
                )}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                <option></option>
                <option>Cash</option>
                <option>Bank</option>
                <option>Mobile</option>
              </Form.Select>
              {!!touched.payment_method && (
                <Form.Control.Feedback type="invalid">
                  {errors.payment_method}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Student name</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="student_name"
                placeholder=""
                value={values.student_name}
                isInvalid={Boolean(touched.student_name && errors.student_name)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.student_name && (
                <Form.Control.Feedback type="invalid">
                  {errors.student_name}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Amount paid</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="amount"
                placeholder=""
                value={values.amount}
                isInvalid={Boolean(touched.amount && errors.amount)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.amount && (
                <Form.Control.Feedback type="invalid">
                  {errors.amount}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </ActionableOffcanvas>
        </Form>
      )}
    </Formik>
  );
};

export default FeePaymentForm;
