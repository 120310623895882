import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import FullTable from "../../../../ui/tables/FullTable";
import ReceiptModal from "./ReceiptModal";
import FeePaymentForm from "../forms/FeePaymentForm";
import SendReminderForm from "../forms/SendReminderForm";
const FeeDetailsPayementsTable = () => {
  const [showPayDetails, setShowPayDetails] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showReminderForm, setShowReminderForm] = useState(false);
  const [editValues, setEditValues] = useState(null);

  const initValues = {
    payment_method: "Bank",
    amount: "TZS 980000",
    student_name: "Brycen Masmo",
  };
  const handleReceiptClick = (id) => {
    setShowPayDetails(true);
    setCurrentId(id);
  };
  const handlePaymentClick = (id, init) => {
    setShowPaymentForm(true);
    setEditValues(init);
    setCurrentId(id);
  };
  const handleClose = () => {
    setShowPayDetails(false);
    setShowPaymentForm(false);
    setShowReminderForm(false);
  };
  const cols = [
    {
      Header: "Student Name",
      accessor: "student_name",
    },
    {
      Header: "Student ID",
      accessor: "student_id",
    },
    {
      Header: "Class/Grade",
      accessor: "grade_level",
    },
    {
      Header: "Payment Status",
      accessor: "payment_status",
    },
    {
      Header: "Payment Date",
      accessor: "payment_date",
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value, row }) => {
        switch (row.values.payment_status) {
          case "Paid":
            return (
              <div className="d-flex flex-row justify-content-between">
                <Button
                  onClick={() => handleReceiptClick(value)}
                  variant="primary"
                >
                  View
                </Button>
                <Button
                  onClick={() => handlePaymentClick(value, row.values)}
                  variant="warning"
                >
                  Edit
                </Button>
              </div>
            );
          case "Not Paid":
            return (
              <div className="d-flex flex-row justify-content-between">
                <Button
                  onClick={() => handlePaymentClick(value, null)}
                  variant="primary"
                >
                  Pay
                </Button>
                <Button
                  onClick={() => setShowReminderForm(true)}
                  variant="warning"
                >
                  Remind
                </Button>
              </div>
            );
          default:
            return <div></div>;
        }
      },
    },
  ];

  const vals = [
    {
      id: "1",
      student_name: "Baraka Elias Urio",
      student_id: "S1447/0046",
      grade_level: "VII",
      payment_status: "Paid",
      payment_date: "21/06/2023 10:49:23AM",
    },
    {
      id: "2",
      student_name: "Baraka Elias Urio",
      student_id: "S1447/0046",
      grade_level: "VII",
      payment_status: "Paid",
      payment_date: "21/06/2023 10:49:23AM",
    },
    {
      id: "3",
      student_name: "Baraka Elias Urio",
      student_id: "S1447/0046",
      grade_level: "VII",
      payment_status: "Not Paid",
      payment_date: null,
    },
    {
      id: "4",
      student_name: "Baraka Elias Urio",
      student_id: "S1447/0046",
      grade_level: "VII",
      payment_status: "Paid",
      payment_date: "21/06/2023 10:49:23AM",
    },
    {
      id: "5",
      student_name: "Baraka Elias Urio",
      student_id: "S1447/0046",
      grade_level: "VII",
      payment_status: "Not Paid",
      payment_date: null,
    },
    {
      id: "5",
      student_name: "Baraka Elias Urio",
      student_id: "S1447/0046",
      grade_level: "VII",
      payment_status: "Paid",
      payment_date: "21/06/2023 10:49:23AM",
    },
    {
      id: "5",
      student_name: "Baraka Elias Urio",
      student_id: "S1447/0046",
      grade_level: "VII",
      payment_status: "Not Paid",
      payment_date: null,
    },
    {
      id: "5",
      student_name: "Baraka Elias Urio",
      student_id: "S1447/0046",
      grade_level: "VII",
      payment_status: "Not Paid",
      payment_date: null,
    },
    {
      id: "5",
      student_name: "Baraka Elias Urio",
      student_id: "S1447/0046",
      grade_level: "VII",
      payment_status: "Paid",
      payment_date: "21/06/2023 10:49:23AM",
    },
    {
      id: "5",
      student_name: "Baraka Elias Urio",
      student_id: "S1447/0046",
      grade_level: "VII",
      payment_status: "Paid",
      payment_date: "21/06/2023 10:49:23AM",
    },
    {
      id: "5",
      student_name: "Baraka Elias Urio",
      student_id: "S1447/0046",
      grade_level: "VII",
      payment_status: "Paid",
      payment_date: "21/06/2023 10:49:23AM",
    },
    {
      id: "5",
      student_name: "Baraka Elias Urio",
      student_id: "S1447/0046",
      grade_level: "VII",
      payment_status: "Paid",
      payment_date: "21/06/2023 10:49:23AM",
    },
    {
      id: "5",
      student_name: "Baraka Elias Urio",
      student_id: "S1447/0046",
      grade_level: "VII",
      payment_status: "Paid",
      payment_date: "21/06/2023 10:49:23AM",
    },
    {
      id: "5",
      student_name: "Baraka Elias Urio",
      student_id: "S1447/0046",
      grade_level: "VII",
      payment_status: "Paid",
      payment_date: "21/06/2023 10:49:23AM",
    },
  ];

  return (
    <React.Fragment>
      <ReceiptModal
        show={showPayDetails}
        id={currentId}
        handleClose={handleClose}
      />
      {showPaymentForm ? (
        <FeePaymentForm
          initVals={editValues ? initValues : null}
          handleClose={handleClose}
        />
      ) : null}
      {showReminderForm ? (
        <SendReminderForm
          fee_id={"111"}
          recipients={"333333"}
          handleClose={handleClose}
        />
      ) : null}
      <Card>
        <Card.Header>
          <h3 className="h3">Payment record</h3>
        </Card.Header>
        <Card.Body>
          <FullTable values={vals} cols={cols} />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
};

export default FeeDetailsPayementsTable;
