import React from "react";
import { Button, Form, Alert } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import SmallCustomCheckbox from "../../../../ui/custom_checkbox/SmallCustomCheckbox";

const MappingForm = () => {
  const subjects = [
    {
      id: "1",
      name: "Basic Mathematics",
    },
    {
      id: "2",
      name: "English",
    },
    {
      id: "3",
      name: "Physics",
    },
    {
      id: "4",
      name: "Commerce",
    },
  ];

  const grades_available = [
    {
      id: "19",
      name: "Elementary",
    },
    {
      id: "1",
      name: "Grade 1",
    },

    {
      id: "2",
      name: "Grade 2",
    },
    {
      id: "3",
      name: "Grade 3",
    },
    {
      id: "4",
      name: "Grade 4",
    },
    {
      id: "5",
      name: "Grade 5",
    },
    {
      id: "6",
      name: "Grade 6",
    },
    {
      id: "7",
      name: "Grade 7",
    },
    {
      id: "8",
      name: "Form 1",
    },
    {
      id: "9",
      name: "Form 2",
    },
    {
      id: "10",
      name: "Form 3",
    },
    {
      id: "11",
      name: "Form 4",
    },
    {
      id: "13",
      name: "Form 5",
    },
    {
      id: "14",
      name: "Form 6",
    },
  ];
  return (
    <Formik
      initialValues={{
        subject: "",
        grades: [],
      }}
      validationSchema={Yup.object().shape({
        subject: Yup.string().required("Subject is required"),
        grades: Yup.array().min(1, "Select at least one grade"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          console.log("Mapping form: ", values);
        } catch (e) {}
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Subject name</Form.Label>
            <Form.Select
              size="lg"
              name="name"
              placeholder=""
              value={values.name}
              isInvalid={Boolean(touched.name && errors.name)}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <option></option>
              {subjects.map((subject) => (
                <option value={subject.id}>{subject.name}</option>
              ))}
            </Form.Select>
            {!!touched.name && (
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Grades taught</Form.Label>
            <div className="d-flex flex-row flex-wrap">
              {grades_available.map((grade) => (
                <SmallCustomCheckbox
                  key={grade.id}
                  val={grade.name}
                  label={grade.name}
                  handleChange={handleChange}
                  name="grades"
                />
              ))}
            </div>
          </Form.Group>
          <div className="d-flex flex-row justify-content-end">
            <Button size="lg" variant="outline-primary">
              {`>>`}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default MappingForm;
