import React from "react";
import { Card, Button } from "react-bootstrap";
import FlatSimpleTable from "../../../ui/tables/FlatSimpleTable";
import { Link } from "react-router-dom";

const UpcomingEventsCard = () => {
  const cols = [
    {
      Header: "",
      accessor: "title",
    },
    {
      Header: "",
      accessor: "link",
      Cell: ({ value }) => {
        return <Button variant="primary">Read more</Button>;
      },
    },
  ];
  const rows = [
    {
      id: 1,
      title: "Closing School Ceremony",
      link: "https://www.google.com",
    },
    {
      id: 2,
      title: "Bridge Exchange Program",
      link: "https://www.google.com",
    },
  ];

  return (
    <Card>
      <Card.Header>
        <h1>Upcoming Events</h1>
      </Card.Header>
      <Card.Body>
        <FlatSimpleTable values={rows} cols={cols} />
      </Card.Body>
    </Card>
  );
};
export default UpcomingEventsCard;
