import React from "react";
import { Button } from "react-bootstrap";
import avatar from "./../../../../assets/img/avatars/avatar.jpg";

const News = ({ school, message, date, link, school_icon }) => {
  return (
    <React.Fragment>
      <h4>{school}</h4>
      <h5>{date}</h5>
      <p>{message}</p>
      <div className="float-end mt-n1">
        <img
          src={avatar}
          width="32"
          height="32"
          className="rounded-circle"
          alt="Avatar"
        />
      </div>
      <Button variant="primary" size="sm">
        View
      </Button>
      <hr></hr>
    </React.Fragment>
  );
};
export default News;
