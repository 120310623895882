import React from "react";
import { Card, Container, Button, Row, Col } from "react-bootstrap";
import FullTable from "../../../ui/tables/FullTable";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const ClassDetails = () => {
  const cols = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Age",
      accessor: "age",
    },
    {
      Header: "Section",
      accessor: "section",
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value }) => (
        <div className="d-flex flex-row justify-content-between">
          <Link to="323424">View</Link>
          <Link to="">Edit</Link>
        </div>
      ),
    },
  ];
  const vals = [
    {
      id: "1",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "A",
    },
    {
      id: "2",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "A",
    },
    {
      id: "3",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "A",
    },
    {
      id: "4",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "B",
    },
    {
      id: "5",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "A",
    },
    {
      id: "6",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "B",
    },
    {
      id: "7",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "A",
    },
    {
      id: "8",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "C",
    },
    {
      id: "9",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "A",
    },
    {
      id: "10",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "A",
    },
    {
      id: "11",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "A",
    },
    {
      id: "12",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "A",
    },
    {
      id: "13",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "A",
    },
    {
      id: "14",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "A",
    },
    {
      id: "15",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "A",
    },
    {
      id: "16",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "A",
    },
    {
      id: "17",
      name: "Brycen Masmo Gumbo",
      age: "5",
      section: "A",
    },
  ];
  return (
    <React.Fragment>
      <Helmet title="Grade 1" />
      <Container fluid className="p-0">
        <div className="d-flex mb-3 flex-row justify-content-between">
          <h1 className="h3 ">Grade 1</h1>
        </div>
        <Row>
          <Col md={6}>
            <Card>
              <Card.Header>
                <h2 className="h2">Grade level: Grade 1</h2>
              </Card.Header>
              <Card.Body>
                <p>
                  Sections: <strong>A, B and C</strong>
                </p>
                <p>
                  Class Teachers:
                  <strong> Michael Minja, Lucy Edison, Ruth Max </strong>
                </p>
                <p>
                  Students: <strong>54</strong>
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Header>
                <h4 className="h4">Class schedule</h4>
              </Card.Header>
              <Card.Body>
                <p>
                  Status: <strong className="h3">Finalized</strong>
                </p>
                <Link to="">View Class schedule</Link>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card>
              <Card.Header>
                <h4 className="h4">Materials & Resources</h4>
              </Card.Header>
              <Card.Body>
                <Link to="">View Class schedule</Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Card>
            <Card.Header>
              <h3 className="h3">Grade 1 Students</h3>
            </Card.Header>
            <Card.Body>
              <FullTable values={vals} cols={cols} />
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ClassDetails;
