import React from "react";
import { Card, ListGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Link2 } from "react-feather";
const Teacher = () => {
  const id = "1111";
  if (id !== null) {
    return (
      <Card>
        <Card.Header>
          <div className="d-flex flex-row justify-content-between">
            <h2 className="h2 mb-3">Teacher information</h2>
            <Button size="sm" variant="outline-primary">
              Change
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong>Name: </strong> Mr. Baraka Elias Urio
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Qualification: </strong> Bch. Degree in Education
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Phonenumber: </strong>255624327900
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Email: </strong>baraka@yahoo.com
            </ListGroup.Item>
            <ListGroup.Item>
              <strong>Additional resources: </strong>
              <Link to="/admin">
                <Link2 size={18} />
              </Link>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    );
  }
  return (
    <Card>
      <Card.Header>
        <div className="d-flex flex-row justify-content-between">
          <h2 className="h2 mb-3">Teacher information</h2>
        </div>
      </Card.Header>
      <Card.Body>
        <Button variant="outline-primary">Assign Teacher</Button>
      </Card.Body>
    </Card>
  );
};
export default Teacher;
