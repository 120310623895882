import React from "react";
import { Card } from "react-bootstrap";
import FullTable from "../../../ui/tables/FullTable";
import { Link } from "react-router-dom";

const ExamsAssesmentsTable = () => {
  const vals = [
    {
      id: "1",
      title: "2023 First Semester Final Examinations",
      subjects: [
        "Basic Mathematics",
        "English",
        "Swahili",
        "Physics",
        "Commerce",
      ],
      grade_levels: [
        "Grade 2",
        "Grade 3",
        "Grade 4",
        "Grade 5",
        "Grade 6",
        "Grade 7",
      ],
      start_date: "2023-06-26",
      end_date: "2023-06-30",
      duration: "5 days",
      status: "Upcoming",
      academic_year: "2023",
    },
    {
      id: "5",
      title: "2023 First Semester Final Examinations",
      subjects: [
        "Basic Mathematics",
        "English",
        "Swahili",
        "Physics",
        "Commerce",
      ],
      grade_levels: [
        "Grade 2",
        "Grade 3",
        "Grade 4",
        "Grade 5",
        "Grade 6",
        "Grade 7",
      ],
      start_date: "2023-06-26",
      end_date: "2023-06-30",
      duration: "5 days",
      status: "Upcoming",
      academic_year: "2022",
    },
    {
      id: "2",
      title: "2023 First Semester Final Examinations",
      subjects: [
        "Basic Mathematics",
        "English",
        "Swahili",
        "Physics",
        "Commerce",
      ],
      grade_levels: [
        "Grade 2",
        "Grade 3",
        "Grade 4",
        "Grade 5",
        "Grade 6",
        "Grade 7",
      ],
      start_date: "2023-06-26",
      end_date: "2023-06-30",
      duration: "5 days",
      status: "Upcoming",
      academic_year: "2022",
    },
    {
      id: "3",
      title: "2023 First Semester Final Examinations",
      subjects: [
        "Basic Mathematics",
        "English",
        "Swahili",
        "Physics",
        "Commerce",
      ],
      grade_levels: [
        "Grade 2",
        "Grade 3",
        "Grade 4",
        "Grade 5",
        "Grade 6",
        "Grade 7",
      ],
      start_date: "2023-06-26",
      end_date: "2023-06-30",
      duration: "5 days",
      status: "Upcoming",
      academic_year: "2021",
    },
  ];
  const cols = [
    {
      Header: "Exam/Assesment Name",
      accessor: "title",
    },
    {
      Header: "Academic year",
      accessor: "academic_year",
    },
    {
      Header: "Subjects",
      accessor: "subjects",
    },
    {
      Header: "Grade levels",
      accessor: "grade_levels",
    },
    {
      Header: "Start date",
      accessor: "start_date",
    },
    {
      Header: "End date",
      accessor: "end_date",
    },
    {
      Header: "Duration",
      accessor: "duration",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value }) => (
        <div className="d-flex flex-row justify-content-between">
          <Link to="323424">View</Link>
          <Link to="">Edit</Link>
        </div>
      ),
    },
  ];
  return (
    <Card>
      <Card.Header>
        <h1 className="h2">All examinations</h1>
      </Card.Header>
      <Card.Body>
        <FullTable values={vals} cols={cols} />
      </Card.Body>
    </Card>
  );
};
export default ExamsAssesmentsTable;
