import React, { useState } from "react";
import {
  Card,
  Row,
  Container,
  Col,
  Badge,
  Button,
  Modal,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users, Mail, MessageSquare } from "react-feather";
import QuickActions from "./quick_actions/QuickActions";
import RecentAnnouncements from "./RecentAnnouncements";
import BroadcastForm from "./BroadcastForm";

const ActionModal = () => {
  return (
    <Modal centered size="lg" show={true}>
      <Modal.Header>
        <h1 className="h1"> Communicate with Parents</h1>
      </Modal.Header>
      <Modal.Body>
        <p>Select what you want to do</p>
        <div className="d-flex flex-row justify-content-center">
          <Button className="m-3 p-4" variant="outline-primary">
            <MessageSquare size={36} />
            <p>Send SMS</p>
          </Button>
          <Button disabled className="m-3 p-4" variant="outline-primary">
            <Mail size={36} />
            <p>Send Email</p>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
const CommunicationsPage = () => {
  const [modal, setModal] = useState(false);
  const handleActionClick = (audience) => {
    setModal(true);
  };
  const [openBroadcast, setOpenBroadcast] = useState(false);
  const handleClose = () => setOpenBroadcast(false);
  return (
    <React.Fragment>
      <Helmet title="Communications" />
      {modal ? <ActionModal /> : null}
      {openBroadcast ? <BroadcastForm handleClose={handleClose} /> : null}
      <Container fluid className="p-0">
        <div className="d-flex mb-3 flex-row justify-content-between">
          <h1 className="h3 ">Communications</h1>
          <Button
            onClick={() => setOpenBroadcast(true)}
            variant="outline-primary"
          >
            New Broadcast
          </Button>
        </div>
        <Row>
          <QuickActions handleClicked={handleActionClick} />
        </Row>
        <Row>
          <Col md={4}>
            <RecentAnnouncements />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default CommunicationsPage;
