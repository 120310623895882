import React, { useContext, useEffect } from "react";
import { Card, Row, Col } from "react-bootstrap";
import avatar from "./../../../../assets/img/avatars/avatar.jpg";
import VerticalFlatSimpleTable from "../../../../ui/tables/VerticalFlatSimpleTable";
import useAppDispatch from "../../../../hooks/useAppDispatch";
import { setActiveStudentId } from "../../../../redux/slices/parentStudent";
import NotyfContext from "./../../../../contexts/NotyfContext";
const ChildCard = (props) => {
  const notfy = useContext(NotyfContext);
  const { student } = props;
  const dispatch = useAppDispatch();

  const handleClick = (id) => {
    dispatch(setActiveStudentId(id));
    notfy.success(`Active child: ${student.name}`);
  };

  return (
    <Card
      onClick={(event) => handleClick(student.id)}
      style={{ cursor: "pointer" }}
    >
      <Card.Header>
        <h3>{student.name}</h3>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={4}>
            <img
              src={avatar}
              alt="Chris Wood"
              className="img-fluid rounded"
              width="100%"
              height="152"
            />
          </Col>
          <Col>
            <VerticalFlatSimpleTable student={student} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default ChildCard;
