import React from "react";
import { Users } from "react-feather";
import { Card, Row, Col } from "react-bootstrap";

const StudentCard = () => {
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col md={10}>
            <h1 className="display-3">360</h1>
            <h1>Students</h1>
          </Col>
          <Col>
            <div bg="" className="stat">
              <Users
                className="align-middle text-success"
                width={64}
                height={64}
              />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default StudentCard;
