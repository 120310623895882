import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Alert, Button } from "react-bootstrap";
import ActionableOffcanvas from "../../../../../../ui/actionables/offcanvas/ActionableOffcanvas";
import SmallCustomCheckbox from "../../../../../../ui/custom_checkbox/SmallCustomCheckbox";

const UploadResourceForm = ({ handleClose }) => {
  const tags = [
    {
      id: "1",
      name: "Grade 1",
    },
    {
      id: "19",
      name: "Elementary",
    },
    {
      id: "2",
      name: "Grade 2",
    },
    {
      id: "3",
      name: "Grade 3",
    },
    {
      id: "4",
      name: "Grade 4",
    },
    {
      id: "5",
      name: "Grade 5",
    },
    {
      id: "6",
      name: "Grade 6",
    },
    {
      id: "7",
      name: "Grade 7",
    },
    {
      id: "8",
      name: "Form 1",
    },
    {
      id: "9",
      name: "Form 2",
    },
    {
      id: "10",
      name: "Form 3",
    },
    {
      id: "11",
      name: "Form 4",
    },
  ];
  return (
    <Formik
      initialValues={{
        title: "",
        description: "",
        file: "",
        tags: [],
        grades: [],
        department: "",
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required("Please provide the title"),
        description: Yup.string()
          .min(20)
          .required("Please provide the description"),
        tag: Yup.array().min(1),
        department: Yup.string().required(
          "Please provide the department this subject belongs to"
        ),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          console.log("UPLOAD RESOURCE FORM: ", values);
        } catch (e) {}
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <ActionableOffcanvas
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            action="Upload Resources"
            title="Basic Mathematics"
          >
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}
            <Form.Group className="mb-3">
              <Form.Label>Title</Form.Label>
              <Form.Control
                size="lg"
                type="text"
                name="title"
                placeholder=""
                value={values.title}
                isInvalid={Boolean(touched.title && errors.title)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.title && (
                <Form.Control.Feedback type="invalid">
                  {errors.title}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                size="lg"
                name="description"
                placeholder=""
                value={values.description}
                isInvalid={Boolean(touched.description && errors.description)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.description && (
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>File</Form.Label>
              <Form.Control
                size="lg"
                type="file"
                name="file"
                placeholder=""
                value={values.file}
                isInvalid={Boolean(touched.file && errors.file)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.file && (
                <Form.Control.Feedback type="invalid">
                  {errors.file}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Tags:</Form.Label>
              <div className="d-flex flex-row flex-wrap">
                {tags.map((grade) => (
                  <SmallCustomCheckbox
                    key={grade.id}
                    val={grade.name}
                    label={grade.name}
                    handleChange={handleChange}
                    name="tags"
                  />
                ))}
              </div>
            </Form.Group>
          </ActionableOffcanvas>
        </Form>
      )}
    </Formik>
  );
};

export default UploadResourceForm;
