import {
  Info,
  Award,
  Briefcase,
  Archive,
  UserCheck,
  Columns,
  Users,
  Clipboard,
  BarChart2,
} from "react-feather";

const manageSection = [
  {
    href: "/admin/student-management",
    icon: Award,
    title: "Student Management",
  },
  {
    href: "/admin/teacher-management",
    icon: Users,
    title: "Teacher Management",
  },
  {
    href: "/admin/staff-management",
    icon: Archive,
    title: "Staff Management",
  },
  {
    href: "/admin/financial-management",
    icon: Archive,
    title: "Financial Management",
  },
];

const adminSection = [
  {
    href: "/admin/dashboard",
    icon: Info,
    title: "Dashboard",
  },
  {
    href: "/admin/academic-curriculum",
    icon: Users,
    title: "Academic Curriculum",
  },
  {
    href: "/admin/class-scheduling",
    icon: Archive,
    title: "Class Scheduling",
  },
  {
    href: "/admin/exams-and-assesments",
    icon: Columns,
    title: "Exams & Assesments",
  },
  {
    href: "/admin/resources",
    icon: Clipboard,
    title: "Academic Resources",
  },
  {
    href: "/admin/communications",
    icon: UserCheck,
    title: "Communications",
  },
  {
    href: "/admin/results",
    icon: BarChart2,
    title: "Results",
  },
];

const settingsSection = [
  {
    href: "/admin/ballot-positions",
    icon: Archive,
    title: "Ballot Positions",
  },
];

const navItems = [
  {
    title: "Administration",
    pages: adminSection,
  },
  {
    title: "Manage",
    pages: manageSection,
  },
  {
    title: "Settings",
    pages: settingsSection,
  },
];

export default navItems;
