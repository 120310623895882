import React from "react";

const NoticeBox = () => {
  return (
    <div>
      <h3 className="card-small-title">This is title of the notice</h3>
      <p>
        This is the body of the notice, its mainly the content that says what
        the notice is all about
      </p>
      <div className="d-flex flex-row justify-content-between">
        <p>16th May 2023</p>
        <p>
          By <strong> School Director</strong>
        </p>
      </div>
      <hr />
    </div>
  );
};
export default NoticeBox;
