import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Row,
  Container,
  Col,
  Badge,
  ButtonGroup,
  Button,
  Modal,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users, Mail, MessageSquare } from "react-feather";
import BudgetVariance from "./kpi/BudgetVariance";
import ExpenseRevenueRatio from "./kpi/ExpenseRevenueRatio";
import OutstandingFees from "./kpi/OutstandingFees";
import FeeSetupForm from "./forms/FeeSetupForm";
import RecentPayments from "./kpi/RecentPayments";
import AllFees from "./kpi/AllFees";

const FinancialManagementPage = () => {
  const navigate = useNavigate();
  const [feeSetup, setFeeSetup] = useState(false);
  const handleClose = () => setFeeSetup(false);
  const initVals = {
    fee_title: "",
    amount: "",
    due_date: "",
    for_grades: [],
    program: "",
  };
  return (
    <React.Fragment>
      <Helmet title="Financial Management" />
      <Container fluid className="p-0">
        {feeSetup ? (
          <FeeSetupForm handleClose={handleClose} initVals={initVals} />
        ) : null}
        <div className="d-flex mb-3 flex-row justify-content-between">
          <h1 className="h3 ">Financial Management</h1>
          <ButtonGroup aria-label="Global subject actions">
            <Button onClick={() => setFeeSetup(true)} variant="outline-primary">
              New Fee
            </Button>
            <Button variant="outline-primary">Fee Collection</Button>
            <Button variant="outline-primary">Payment Tracking</Button>
          </ButtonGroup>
        </div>

        <Row>
          {/* <Col md={3}>
            <BudgetVariance />
          </Col>
          <Col md={3}>
            <ExpenseRevenueRatio />
          </Col> */}
          <Col md={6}>
            <RecentPayments />
          </Col>
          <Col>
            <OutstandingFees />
          </Col>
        </Row>
        <Row>
          <AllFees />
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default FinancialManagementPage;
