import React, { useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import FullTable from "../../../../ui/tables/FullTable";
import QuickActions from "../quick_actions/QuickActions";
import { Treebeard } from "react-treebeard";

const OverviewTree = () => {
  const treeStyle = {
    // ...other style definitions
    node: {
      // ...other node styles
      header: {
        base: {
          display: "inline-block",
          verticalAlign: "top",
          color: "#333",
          paddingLeft: "10px", // Add extra padding on the left
        },
      },
      // ...other node styles
    },
  };
  const [data, setData] = useState({
    name: "Academic years",
    toggled: true,
    active: true,
    children: [
      {
        name: "2023",
        children: [
          {
            name: "First semester",
          },
        ],
      },
      {
        name: "2022",
        children: [
          {
            name: "Second semester",
          },
          {
            name: "First semester",
          },
        ],
      },
      {
        name: "2021",
        children: [
          {
            name: "Second semester",
          },
          {
            name: "First semester",
          },
        ],
      },
    ],
  });

  const onToggle = (node, toggled) => {
    if (node.children) {
      node.toggled = toggled;
    }
    setData({ ...data });
  };
  return (
    <React.Fragment>
      Tree
      <Treebeard data={data} onToggle={onToggle} />
    </React.Fragment>
  );
};

export default OverviewTree;
