import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import avatar from "./../../../../assets/img/avatars/avatar.jpg";
import { DollarSign } from "react-feather";

const DueFeesCard = (props) => {
  const { student } = props;
  return (
    <Card>
      <Card.Body>
        <Row>
          <Col bg="dark" md={4}>
            <DollarSign width={"32"} height={"32"} />
          </Col>
          <Col>
            <h3 className="display-5">Tshs.3,600,000</h3>
            <h4>Due fees</h4>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default DueFeesCard;
