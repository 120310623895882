import React from "react";
import { Card, Row, Col, Alert, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import TinyCustomCheckbox from "../../../ui/custom_checkbox/TinyCustomCheckbox";

const CommunicationForm = () => {
  const parentCategories = [
    {
      id: "1",
      title: "Grade 1",
    },
    {
      id: "2",
      title: "Grade 2",
    },
    {
      id: "3",
      title: "Grade 3",
    },
    {
      id: "4",
      title: "Grade 4",
    },
    {
      id: "5",
      title: "Grade 5",
    },
    {
      id: "6",
      title: "Grade 6",
    },
    {
      id: "7",
      title: "Grade 7",
    },
    {
      id: "8",
      title: "Form 1",
    },
    {
      id: "9",
      title: "Form 2",
    },
    {
      id: "10",
      title: "Form 3",
    },
    {
      id: "11",
      title: "Form 4",
    },
    {
      id: "12",
      title: "Form 5",
    },
    {
      id: "13",
      title: "Form 6",
    },
  ];
  return (
    <Card>
      <Card.Header>
        <h2 className="h2 mb-1">Send an SMS or Email</h2>
      </Card.Header>
      <Card.Body>
        <Formik
          initialValues={{
            all_recipients: "",
            recipients: [],
            delivery_method: "",
            comm_type: "",
            title: "",
            message: "",
            schedule: "",
          }}
          validationSchema={Yup.object().shape({
            recipients: Yup.mixed().when("all_recipients", {
              is: "",
              then: Yup.array().min("Select at least one recipient"),
            }),
            delivery_method: Yup.string().required("Specify method of deliver"),
            comm_type: Yup.string().required(
              "Specify the purpose of the Communication eg. General, Event, Academic"
            ),
            title: Yup.string().required("A title is required"),
            message: Yup.string()
              .max(160, "Too many characters!")
              .required("Message is required!"),
            schedule: Yup.string(),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              console.log("SEND SMS: ", values);
            } catch (e) {}
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              <Form.Group className="mb-3">
                <Form.Label>Recipients</Form.Label>
                <Form.Check
                  size="lg"
                  label="All parents"
                  name="all_recipients"
                  placeholder=""
                  value="yes"
                  isInvalid={Boolean(
                    touched.all_recipients && errors.all_recipients
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <div className="d-flex flex-row flex-wrap">
                  {parentCategories.map((cat) => (
                    <TinyCustomCheckbox
                      key={cat.id}
                      disabled={values.all_recipients === "yes" ? true : false}
                      handleChange={handleChange}
                      name="recipients"
                      label={cat.title}
                      val={cat.title}
                    />
                  ))}
                </div>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Delivery Method</Form.Label>
                    <Form.Select
                      size="lg"
                      name="delivery_method"
                      placeholder=""
                      value={values.delivery_method}
                      isInvalid={Boolean(
                        touched.delivery_method && errors.delivery_method
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <option value="all">All</option>
                      <option value="sms">SMS</option>
                      <option value="email">Email</option>
                    </Form.Select>
                    {!!touched.delivery_method && (
                      <Form.Control.Feedback type="invalid">
                        {errors.delivery_method}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Communication Type</Form.Label>
                    <Form.Select
                      size="lg"
                      name="comm_type"
                      placeholder=""
                      value={values.comm_type}
                      isInvalid={Boolean(touched.comm_type && errors.comm_type)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    >
                      <option value="general">General</option>
                      <option value="announcement">Announcement</option>
                      <option value="invite">Event Invintation</option>
                      <option value="school_closure">School Closure</option>
                      <option value="academic">Academic</option>
                    </Form.Select>
                    {!!touched.comm_type && (
                      <Form.Control.Feedback type="invalid">
                        {errors.comm_type}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <h4 className="h4 mb-3">Message content:</h4>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  name="title"
                  placeholder=""
                  value={values.title}
                  isInvalid={Boolean(touched.title && errors.title)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.title && (
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  size="lg"
                  as="textarea"
                  name="message"
                  rows={5}
                  placeholder=""
                  value={values.message}
                  isInvalid={Boolean(touched.message && errors.message)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.message && (
                  <Form.Control.Feedback type="invalid">
                    {errors.message}
                  </Form.Control.Feedback>
                )}
                <ul>
                  <li>Characters: {values.message.length}</li>
                  <li>Per Message: 160</li>
                  <li>Remainig: {160 - values.message.length}</li>
                </ul>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Schedule</Form.Label>
                <Form.Control
                  size="lg"
                  type="datetime-local"
                  name="schedule"
                  placeholder=""
                  value={values.schedule}
                  isInvalid={Boolean(touched.schedule && errors.schedule)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.schedule && (
                  <Form.Control.Feedback type="invalid">
                    {errors.schedule}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <div className="d-flex mt-4 flex-row justify-content-end">
                <Button type="submit" size="lg" variant="primary">
                  Send
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </Card>
  );
};

export default CommunicationForm;
