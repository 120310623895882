import React from "react";
import { Card, Row, Container, Col, Badge } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";
import {
  faSort,
  faSortUp,
  faSquarePollVertical,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ChildCard from "./cards/ChildCard";
import { useGetAllParentStudentsQuery } from "../../../redux/slices/parent/mystudents";
import DueFeesCard from "./cards/DueFeesCard";
import NewsCard from "./cards/NewsCard";

const ParentDash = () => {
  var my_students = [
    {
      id: 1,
      name: "Brycen M.",
      school_name: "Feza boys",
      gender: "Male",
      class: "Form 2A",
      admission_date: "04/05/2022",
    },
    {
      id: 2,
      name: "Prisca B",
      school_name: "Byu High",
      gender: "Female",
      class: "Form 4 Science",
      admission_date: "01/01/2021",
    },
    {
      id: 3,
      name: "Prisca B",
      school_name: "Byu High",
      gender: "Female",
      class: "Form 4 Science",
      admission_date: "01/01/2021",
    },
    {
      id: 4,
      name: "Prisca B",
      school_name: "Byu High",
      gender: "Female",
      class: "Form 4 Science",
      admission_date: "01/01/2021",
    },
  ];
  const { data, error, isLoading } = useGetAllParentStudentsQuery();
  if (!isLoading) {
    //set my_students = data
  }
  return (
    <React.Fragment>
      <Helmet title="Parent Dashboard" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">My students</h1>
        <Row>
          <Col md={9}>
            {/* My Students Row */}
            <Row>
              {my_students.map((student) => (
                <Col key={student.id} md={"4"}>
                  <ChildCard student={student} />
                </Col>
              ))}
            </Row>
          </Col>
          <Col>
            <DueFeesCard />
            <NewsCard />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ParentDash;
