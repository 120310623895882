import { User } from "react-feather";
import {
  faUser,
  faChalkboardTeacher,
  faPerson,
  faCalendar,
  faMoneyBill,
  faBook,
  faMailBulk,
  faScroll,
} from "@fortawesome/free-solid-svg-icons";
export const quick_actions_list = [
  {
    title: "Student Admissions",
    icon: faUser,
    link: "/admin/student-management/enroll-new-student",
  },
  {
    title: "Teaching Staff",
    icon: faChalkboardTeacher,
    link: "/admin/teacher-management",
  },
  {
    title: "Non-teaching Staff",
    icon: faPerson,
    link: "/admin",
  },
  {
    title: "School events",
    icon: faCalendar,
    link: "/admin",
  },
  {
    title: "Payments",
    icon: faMoneyBill,
    link: "/admin/financial-management",
  },

  {
    title: "Send Message",
    icon: faMailBulk,
    link: "/admin/communications/all",
  },
  {
    title: "New Notice",
    icon: faScroll,
    link: "/admin",
  },
];
