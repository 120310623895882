import React from "react";
import { Users } from "react-feather";
import { Card, Row, Col } from "react-bootstrap";
import ActiveStudentsDonught from "./ActiveStudentsDonught";
import WithdrawnStudentsDonught from "./WithdrawnStudentsDonught";
import SuspendedStudentsDonught from "./SuspendedStudentsDonught";

const EnrollmentStatusCard = () => {
  return (
    <Card>
      <Card.Header>
        <Card.Title tag="h3">Enrollment Status</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <h4>Active</h4>
            <ActiveStudentsDonught />
          </Col>
          <Col>
            <h4>Withdrawn</h4>
            <WithdrawnStudentsDonught />
          </Col>
          <Col>
            <h4>Suspended</h4>
            <SuspendedStudentsDonught />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
export default EnrollmentStatusCard;
