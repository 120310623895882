import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Row,
  Container,
  Col,
  Badge,
  ListGroup,
  ButtonGroup,
  Button,
  Modal,
} from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users, Mail, MessageSquare } from "react-feather";
import FeeDetailsPayementsTable from "./FeeDetailsPayementsTable";
import ReceiptModal from "./ReceiptModal";
import FeeSetupForm from "../forms/FeeSetupForm";
import SendReminderForm from "../forms/SendReminderForm";

const FeeDetailsPage = () => {
  const navigate = useNavigate();
  const [showFeeSetupForm, setShowFeeSetupForm] = useState(false);
  const [showSendReminderForm, setShowSendReminderForm] = useState(false);

  const initVal = {
    fee_title: "Edit tuition fee title",
    amount: "48498493939",
    due_date: "",
    for_grades: ["Grade 4", "Grade 5", "Grade 6"],
    program: "",
  };
  const handleClose = () => {
    setShowSendReminderForm(false);
    setShowFeeSetupForm(false);
  };

  return (
    <React.Fragment>
      <Helmet title="Financial Management" />
      <Container fluid className="p-0">
        {showFeeSetupForm ? (
          <FeeSetupForm handleClose={handleClose} initVals={initVal} />
        ) : null}
        {showSendReminderForm ? (
          <SendReminderForm
            fee_id={"111"}
            recipients={"all"}
            handleClose={handleClose}
          />
        ) : null}
        <div className="d-flex mb-3 flex-row justify-content-between">
          <h1 className="h3 ">Fee Details</h1>
          <ButtonGroup aria-label="Global subject actions">
            <Button variant="outline-primary">Make a Payment</Button>
            <Button
              onClick={() => setShowSendReminderForm(true)}
              variant="outline-primary"
            >
              Send Reminder
            </Button>
            <Button variant="outline-primary">Print</Button>
          </ButtonGroup>
        </div>
        <Row>
          <Card>
            <Card.Header>
              <h1 className="h3 mb-3">Tuition Fee - Academic Year 2023-2024</h1>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md={7}>
                  <h2 className="h4 mb-2">Description</h2>
                  <p className="mb-5">
                    The tuition fee covers the cost of education for the
                    specified year. It includes various academic services and
                    resources provied to the students throughout the year. The
                    fee contributes towards maintaining high-quality teaching
                    standards, supporting educational programs, and ensuring a
                    conducive learning environment for students.
                  </p>
                </Col>
                <Col>
                  <div className="d-flex flex-row justify-content-end">
                    <ButtonGroup aria-label="Global subject actions">
                      <Button
                        onClick={() => setShowFeeSetupForm(true)}
                        variant="outline-primary"
                      >
                        Edit Fee
                      </Button>
                      <Button variant="outline-danger">Delete Fee</Button>
                    </ButtonGroup>
                  </div>
                </Col>
              </Row>
              <Row>
                <h1 className="h3 mb-3">Details</h1>

                <div style={{ overflowX: "auto" }}>
                  <ListGroup flush className="mb-3" horizontal>
                    <ListGroup.Item>Fee Amount: TZS980,000</ListGroup.Item>
                    <ListGroup.Item>Due Date: 30/06/2023</ListGroup.Item>
                    <ListGroup.Item>Status: Completed</ListGroup.Item>
                    {/* {grades_taught.map((grade) => {
                      return (
                        <ListGroup.Item
                          action
                          onClick={() => setCurrentGrade(grade.id)}
                          key={grade.id}
                        >
                          {grade.name}
                        </ListGroup.Item>
                      );
                    })} */}
                  </ListGroup>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </Row>
        <Row>
          <FeeDetailsPayementsTable />
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default FeeDetailsPage;
