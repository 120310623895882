import React from "react";
import { Card, Row, Container, Col, Badge, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Layers, Users } from "react-feather";
import {
  faSort,
  faSortUp,
  faSquarePollVertical,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FullTable from "../../../ui/tables/FullTable";

const Curriculum = () => {
  const handleClick = () => {};
  const cols = [
    {
      Header: "Academic year",
      accessor: "academic_year",
      Cell: ({ value }) => {
        return <h3>{value}</h3>;
      },
    },
    {
      Header: "Subject",
      accessor: "subject",
    },
    {
      Header: "Teacher",
      accessor: "teacher",
    },
  ];
  const rows = [
    {
      id: 1,
      subject: "Mathematics",
      teacher: "Mr. Samwel Walter",
    },
    {
      id: 2,
      subject: "English",
      teacher: "Mr. Samwel Walter",
    },
  ];
  return (
    <React.Fragment>
      <Helmet title="Curriculum" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3 mt-2">My Curriculum</h1>
        <Card>
          <Card.Body>
            <FullTable values={rows} cols={cols} />
          </Card.Body>
        </Card>
      </Container>
    </React.Fragment>
  );
};

export default Curriculum;
