import React, { useState } from "react";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import Fulltable from "../../../../ui/tables/FullTable";
import { Link } from "react-router-dom";
import ActionableOffcanvas from "../../../../ui/actionables/offcanvas/ActionableOffcanvas";
import CreateProgramForm from "./CreateProgramForm";

const ManageProgramsPage = () => {
  const [createProgramForm, setCreateProgramForm] = useState(false);
  const handleClose = () => {
    setCreateProgramForm(false);
  };
  const vals = [
    {
      id: "1",
      name: "Swimming Lessons",
      description:
        "A program designed to teach simming skills and water safety to students in grade 3 and 4",
      type: "Activity",
      coordinator: "John Smith",
      enrollment_count: "26",
      duration: "4 months",
      start_date: "2023-05-30",
      end_date: "2023-09-30",
    },
    {
      id: "2",
      name: "Art Lessons",
      description:
        "This program offers creative at lessons to students in grades 5 and 6. Students will explore various art techniiques and mediums, including painting, drawing and sculpture.",
      type: "Activity",
      coordinator: "John Smith",
      enrollment_count: "26",
      duration: "4 months",
      start_date: "2023-05-30",
      end_date: "2023-09-30",
    },
    {
      id: "3",
      name: "Swimming Lessons",
      description:
        "A program designed to teach simming skills and water safety to students in grade 3 and 4",
      type: "Activity",
      coordinator: "John Smith",
      enrollment_count: "26",
      duration: "4 months",
      start_date: "2023-05-30",
      end_date: "2023-09-30",
    },
  ];
  const cols = [
    {
      Header: "Program name",
      accessor: "name",
    },
    {
      Header: "Description",
      accessor: "description",
      style: { width: "200px" },
    },
    {
      Header: "Program Type",
      accessor: "type",
    },
    {
      Header: "Program Coordinator",
      accessor: "coordinator",
    },
    {
      Header: "Enrollment count",
      accessor: "enrollment_count",
    },
    {
      Header: "Start date",
      accessor: "start_date",
    },
    {
      Header: "End date",
      accessor: "end_date",
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value }) => (
        <div className="d-flex flex-row justify-content-between">
          <Link className="p-2" to="program-id">
            View
          </Link>
          <Link className="p-2" to="/admin">
            Edit
          </Link>
          <Link className="p-2" to="/admin">
            Delete
          </Link>
        </div>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Helmet title="Manage programs" />
      {createProgramForm ? (
        <CreateProgramForm handleClose={handleClose} />
      ) : null}
      <Container fluid className="p-0">
        <div className="d-flex flex-row justify-content-between">
          <h1 className="h3 mb-3">Manage Programs</h1>
          <Button
            onClick={(e) => setCreateProgramForm(true)}
            variant="outline-primary"
          >
            Create Program
          </Button>
        </div>
        <Row>
          <Card>
            <Card.Header>
              <h3 className="h3">Available programs</h3>
            </Card.Header>
            <Card.Body>
              <Fulltable cols={cols} values={vals} />
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ManageProgramsPage;
