import React from "react";
import { Row, Col, Card, ListGroup, Button } from "react-bootstrap";
import { Link2 } from "react-feather";
import { Link } from "react-router-dom";
import Teacher from "./Teacher";

const CurrentGrade = () => {
  const id = "111111";
  const subject_grade_curriculum = [
    "Using mathematical language to present ideas to solve real-life problems (Part One)",
    "Using reasoning and proof in real-life situations (Part One)",
    "Solving mathematical problems in different situations",
    "Using reasoning and proof in real-life situations (Part Two)",
    "Using mathematical language to present ideas to solve real-life problems (Part Two)",
  ];
  return (
    <Row>
      <h1 className="h3 mb-3">Basic Mathematics for Grade 7</h1>

      <Col md={4}>
        <Card>
          <Card.Header>
            <h2 className="h2 mb-3">Curriculum</h2>
          </Card.Header>
          <Card.Body>
            <ListGroup as="ol" numbered>
              {subject_grade_curriculum.map((curriculum) => (
                <ListGroup.Item as="li">{curriculum}</ListGroup.Item>
              ))}
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4}>
        <Teacher />
      </Col>
      <Col>
        <Row>
          <Card>
            <Card.Header>
              <h2 className="h2 mb-3">Resources</h2>
            </Card.Header>
            <Card.Body>
              There 8 resources.{" "}
              <Link to="/admin">
                <Link2 size={18} />
              </Link>
            </Card.Body>
          </Card>
        </Row>
        <Row>
          <Card>
            <Card.Header>
              <h2 className="h2 mb-3">Additional support</h2>
            </Card.Header>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  Study Tips and Techniques:{" "}
                  <Link to="/admin">
                    <Link2 size={18} />
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  Assesment Guidelines:{" "}
                  <Link to="/admin">
                    <Link2 size={18} />
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-flex flex-row justify-content-between">
                    <p className="fs-4">Report an error</p>
                    <Button className="rounded" variant="outline-warning">
                      Go
                    </Button>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Row>
      </Col>
    </Row>
  );
};

export default CurrentGrade;
