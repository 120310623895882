import React from "react";
import { Button, Card } from "react-bootstrap";
import FullTable from "../../../../ui/tables/FullTable";
import { Link } from "react-router-dom";

const AllFees = () => {
  const cols = [
    {
      Header: "Fee Name",
      accessor: "fee_name",
    },
    {
      Header: "Description",
      accessor: "description",
    },

    {
      Header: "Amount",
      accessor: "total_amount",
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value }) => (
        <div className="d-flex flex-row justify-content-between">
          <Link to="323424">View</Link>
          <Link to="">Edit</Link>
        </div>
      ),
    },
  ];
  const vals = [
    {
      id: "1",
      fee_name: "Tuition Fee",
      description: "Baraka Tuition Fees",
      total_amount: "980000",
      date: "10/ 06/2023 13:42:03",
    },
    {
      id: "1",
      fee_name: "Exam Fee",
      description: "Baraka Tuition Fees",
      total_amount: "20000",
      date: "10/ 06/2023 13:42:03",
    },
    {
      id: "1",
      fee_name: "Registration Fee",
      description: "Baraka Elias Urio",
      total_amount: "15000",
      date: "10/ 06/2023 13:42:03",
    },
    {
      id: "1",
      fee_name: "Tuition Fee",
      description: "Brycen Tuition Fees",
      total_amount: "980000",
      date: "10/ 06/2023 13:42:03",
    },
    {
      id: "1",
      fee_name: "Tuition Fee",
      description: "Baraka Tuition Fees",
      total_amount: "980000",
      date: "10/ 06/2023 13:42:03",
    },
  ];
  return (
    <Card>
      <Card.Header>
        <h2 className="h2">All Fees</h2>
      </Card.Header>
      <Card.Body>
        <FullTable values={vals} cols={cols} />
      </Card.Body>
    </Card>
  );
};

export default AllFees;
