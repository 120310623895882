import React from "react";
import { Row, Container, Card } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import FullTable from "../../../ui/tables/FullTable";

const ClassSchedulingPage = () => {
  const cols = [
    {
      Header: "Grade Level",
      accessor: "grade_level",
    },
    {
      Header: "Number of sections",
      accessor: "num_of_sections",
    },
    {
      Header: "Teachers assigned",
      accessor: "teachers_assigned",
      Cell: ({ value }) => (
        <div className="d-flex flex-row">
          {value.map((teacher) => (
            <p key={teacher} className="p-1">
              {teacher},
            </p>
          ))}
        </div>
      ),
    },
    {
      Header: "Class size",
      accessor: "class_size",
    },
    {
      Header: "Schedule Status",
      accessor: "schedule_status",
    },
    {
      Header: "Actions",
      accessor: "id",
      Cell: ({ value }) => (
        <div className="d-flex flex-row justify-content-between">
          <Link to="grade_id" className="p-2">
            View
          </Link>
          <Link className="p-2" to="/admin">
            Edit
          </Link>
          <Link className="p-2" to="/admin">
            Delete
          </Link>
        </div>
      ),
    },
  ];
  const vals = [
    {
      grade_level: "Grade 1",
      num_of_sections: "2",
      teachers_assigned: ["Agness Johansen", "John Snow", "Daeneris Tygarian"],
      class_size: "30",
      schedule_status: "Finalized",
    },
    {
      grade_level: "Grade 2",
      num_of_sections: "3",
      teachers_assigned: ["Agness Johansen", "John Snow", "Daeneris Tygarian"],
      class_size: "30",
      schedule_status: "In progress",
    },
    {
      grade_level: "Grade 3",
      num_of_sections: "4",
      teachers_assigned: ["Agness Johansen", "John Snow", "Daeneris Tygarian"],
      class_size: "30",
      schedule_status: "Finalized",
    },
  ];
  return (
    <React.Fragment>
      <Helmet title="Class Scheduling" />
      <Container fluid className="p-0">
        <div className="d-flex flex-row justify-content-between">
          <h1 className="h3 mb-3">Class Scheduling</h1>
        </div>
        <Row>
          <Card>
            <Card.Header>
              <h1 className="h2 mb-3"> All Class Schedules</h1>
            </Card.Header>
            <Card.Body>
              <FullTable cols={cols} values={vals} />
            </Card.Body>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ClassSchedulingPage;
