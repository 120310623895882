import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import counterReducer from "./slices/counter";
import userReducer from "./slices/user";
import authSlice from "./slices/authSlice";
import parentStudent from "./slices/parentStudent";
//RTK QUERY
import { parentstudentsApi } from "./slices/parent/mystudents";
export const store = configureStore({
  reducer: {
    user: userReducer,
    authSlice: authSlice,
    parentStudent: parentStudent,
    [parentstudentsApi.reducerPath]: parentstudentsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([parentstudentsApi.middleware]),
});
