import React from "react";
import { Row, Container, Card, Col, ListGroup } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import FullTable from "../../../../ui/tables/FullTable";
import ClassSchedule from "./ClassSchedule";

const Navigation = () => (
  <Card>
    <Card.Header>
      <Card.Title tag="h5" className="mb-0">
        Grade Sections
      </Card.Title>
    </Card.Header>
    <ListGroup variant="flush">
      <ListGroup.Item tag="a" href="#a" action>
        General Information
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#b" action>
        Grade 2 (A)
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#c" action>
        Grade 2 (B)
      </ListGroup.Item>
      <ListGroup.Item tag="a" href="#d" action>
        Grade 2 (C)
      </ListGroup.Item>
    </ListGroup>
  </Card>
);
const ClassSchedulingGradePage = () => {
  return (
    <React.Fragment>
      <Helmet title="Class Scheduling" />
      <Container fluid className="p-0">
        <div className="d-flex flex-row justify-content-between">
          <h1 className="h3 mb-3">Grade 2</h1>
        </div>
        <Row>
          <Col md={2}>
            <Navigation />
          </Col>
          <Col>
            <ClassSchedule />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default ClassSchedulingGradePage;
