import React from "react";
import TimeTable from "react-timetable-events";
import { Card } from "react-bootstrap";
import EditClassSchedule from "./EditClassSchedule";

const ClassSchedule = () => {
  const schedule1 = {
    monday: [
      {
        id: 1,
        name: "Mathematics",
        type: "custom",
        startTime: new Date("2022-12-01T11:30:00"),
        endTime: new Date("2022-12-01T13:30:00"),
      },
      {
        id: 2,
        name: "English",
        type: "custom",
        startTime: new Date("2022-12-01T13:40:00"),
        endTime: new Date("2022-12-01T15:30:00"),
      },
      {
        id: 3,
        name: "Free period",
        type: "custom",
        startTime: new Date("2022-12-01T15:35:00"),
        endTime: new Date("2022-12-01T16:30:00"),
      },
    ],
    tuesday: [
      {
        id: 1,
        name: "Mathematics",
        type: "custom",
        startTime: new Date("2022-12-01T11:30:00"),
        endTime: new Date("2022-12-01T13:30:00"),
      },
      {
        id: 2,
        name: "English",
        type: "custom",
        startTime: new Date("2022-12-01T13:40:00"),
        endTime: new Date("2022-12-01T15:30:00"),
      },
      {
        id: 3,
        name: "Free period",
        type: "custom",
        startTime: new Date("2022-12-01T15:35:00"),
        endTime: new Date("2022-12-01T16:30:00"),
      },
    ],
    wednesday: [
      {
        id: 1,
        name: "Mathematics",
        type: "custom",
        startTime: new Date("2022-12-01T11:30:00"),
        endTime: new Date("2022-12-01T13:30:00"),
      },
      {
        id: 2,
        name: "English",
        type: "custom",
        startTime: new Date("2022-12-01T13:40:00"),
        endTime: new Date("2022-12-01T15:30:00"),
      },
      {
        id: 3,
        name: "Free period",
        type: "custom",
        startTime: new Date("2022-12-01T15:35:00"),
        endTime: new Date("2022-12-01T16:30:00"),
      },
    ],
    thursday: [
      {
        id: 1,
        name: "Mathematics",
        type: "custom",
        startTime: new Date("2022-12-01T11:30:00"),
        endTime: new Date("2022-12-01T13:30:00"),
      },
      {
        id: 2,
        name: "English",
        type: "custom",
        startTime: new Date("2022-12-01T13:40:00"),
        endTime: new Date("2022-12-01T15:30:00"),
      },
      {
        id: 3,
        name: "Free period",
        type: "custom",
        startTime: new Date("2022-12-01T15:35:00"),
        endTime: new Date("2022-12-01T16:30:00"),
      },
    ],
    friday: [
      {
        id: 1,
        name: "Mathematics",
        type: "custom",
        startTime: new Date("2022-12-01T08:00:00"),
        endTime: new Date("2022-12-01T10:30:00"),
      },
      {
        id: 2,
        name: "English",
        type: "custom",
        startTime: new Date("2022-12-01T13:40:00"),
        endTime: new Date("2022-12-01T15:30:00"),
      },
      {
        id: 3,
        name: "Free period",
        type: "custom",
        startTime: new Date("2022-12-01T15:35:00"),
        endTime: new Date("2022-12-01T16:30:00"),
      },
    ],
  };
  const initialSchedule = [
    {
      id: "1",
      name: "Mathematics",
      day: 1,
      startTime: "09:00",
      endTime: "10:30",
    },
  ];
  return (
    <Card>
      <Card.Header>
        <h1 className="h2 mb-3">Schedule for Section A</h1>
      </Card.Header>
      <Card.Body>
        <TimeTable
          className="daily-schedule-card-timetable"
          events={schedule1}
          hoursInterval={{
            from: 8,
            to: 17,
          }}
          style={{ height: "400px" }}
        />
        {/* <EditClassSchedule /> */}
      </Card.Body>
    </Card>
  );
};

export default ClassSchedule;
